<template>
  <div class="flex flex-col min-h-screen">
    <div class="shadow-md">
      <Header />
    </div>
    <Auth background="bg-white" height="h-full">
      <div v-if="!verify" class="md:mx-14 mx-3 my-14">
        <h1 class="font-semibold text-4xl">Sign up</h1>
        <p class="mt-4"></p>
        <form @submit.prevent="registerUser" class="mt-4">
          <div v-if="error.empty" class="text-sm text-red-500 my-4">
            {{ error.empty }}
          </div>
          <div class="grid grid-cols-2 gap-2 lg:gap-4">
            <div>
              <label class="text-sm" for="firstName">First Name</label>
              <input
                type="text"
                v-model="firstName"
                class="input"
                placeholder="First Name"
              />
              <span v-if="error.firstName" class="text-sm text-red-500 my-4">{{
                error.firstName
              }}</span>
            </div>
            <div>
              <label for="firstName">Last Name</label>
              <input
                type="text"
                v-model="lastName"
                class="input"
                placeholder="Last Name"
              />
              <span v-if="error.lastName" class="text-sm text-red-500 my-4">{{
                error.lastName
              }}</span>
            </div>
          </div>
          <div class="grid grid-rows-1 lg:grid-cols-2 gap-2 lg:gap-4 mt-4">
            <div>
              <label for="firstName">E-mail Address</label>
              <input
                type="text"
                v-model="email"
                class="input"
                placeholder="E-mail Address"
              />
              <span v-if="error.email" class="text-sm text-red-500 my-4">{{
                error.email
              }}</span>
            </div>
            <div>
              <label for="firstName">Phone number</label>
              <input
                type="text"
                v-model="phone"
                class="input"
                placeholder="Phone Number"
              />
              <span v-if="error.phone" class="text-sm text-red-500 my-4">{{
                error.phone
              }}</span>
            </div>
          </div>
          <div class="md:grid md:grid-cols-2 gap-2 lg::gap-4 mt-4">
            <div class="">
              <label for="password">Password</label>
              <input
                type="password"
                v-model="password"
                class="input"
                placeholder="Password"
                autocomplete="off"
              />
            </div>
            <div class="mt-4 md:mt-0">
              <label for="cpassword">Confirm Password</label>
              <input
                type="password"
                v-model="cpassword"
                class="input"
                placeholder="Confirm password"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="mt-4">
            <div class="">
              <div class="flex space-x-1 items-center">
                <input type="checkbox" v-model="showDiscount" name="" id="" />
                <p><span class="text-sm">Have Promotion code?</span></p>
              </div>
              <input
                v-if="showDiscount"
                type="text"
                v-model="promotionCode"
                class="input"
                placeholder="Promotions Code"
              />
            </div>
          </div>
          <div class="mt-4">
            <div class="">
              <label for="referralCode">Referral Code</label>
              <input
                :disabled="referralCodeInUrl"
                type="text"
                v-model="referralCode"
                class="input"
                placeholder="Referral Code"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="md:grid md:grid-cols-1 md:gap-4">
            <div class="mt-3 text-sm">
              <input type="checkbox" class="mt-3" v-model="terms" /> By clicking
              on 'create account' you agree to Noshtribe's
              <a href="#" class="text-brand font-semibold">Privacy Policy</a>
              and
              <a href="#" class="text-brand font-semibold"
                >Terms and conditions</a
              >
            </div>
          </div>
          <div class="mt-4 block">
            <button
              ref="loginBtn"
              class="bg-brand flex justify-center p-3 mb-4 text-sm w-full font-semibold rounded"
            >
              <Preloader v-if="showPreloader" />
              <span v-else>Create Account</span>
            </button>
            <p class="flex-grow text-center text-sm">
              Already have an account?
              <router-link
                :to="{ name: 'CustomerSignIn' }"
                class="text-brand font-semibold ml-1"
              >
                Login</router-link
              >
            </p>
          </div>
        </form>
      </div>
    </Auth>
  </div>
</template>

<script>
import Auth from "@/components/admin/Auth.vue";
import Header from "@/components/Header.vue";
import Preloader from "@/components/Preloader.vue";
export default {
  name: "CustomerSignUp",
  components: {
    Auth,
    Preloader,
    Header,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      cpassword: "",
      referralCode: "",
      referralCodeInUrl: false,
      verify: false,
      showPreloader: false,
      terms: false,
      resendMsg: null,
      showDiscount: false,
      promotionCode: "",
      error: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        password: "",
        promotionCode: "",
        referralCode: "",
      },
    };
  },
  methods: {
    toggleDiscount() {
      this.showDiscount = !this.showDiscount;
    },
    registerUser() {
      let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      let regexPhone = /^[0]\d{10}$/gm;
      if (
        !this.firstName ||
        !this.lastName ||
        !this.email ||
        !this.phone ||
        !this.password ||
        !this.cpassword
      ) {
        this.error.empty = "All fields are required";
      } else if (!this.email.match(regexEmail)) {
        this.error.empty = "Invaild E-mail address";
      } else if (!this.phone.match(regexPhone)) {
        this.error.empty = "Invaild phone number";
      } else if (this.password.length < 6) {
        this.error.empty = "Password too short (Minimum of 6 characters)";
      } else if (this.password !== this.cpassword) {
        this.error.empty = "Password given do not match";
      } else if (this.showDiscount && !this.promotionCode) {
        this.error.empty = "Promotion Code is required";
      } else if (this.terms !== true) {
        this.error.empty = "Accept our terms and conditions";
      } else {
        this.error.empty = "";
        this.$refs.loginBtn.disabled = true;
        this.showPreloader = true;
        //Calling the api
        let data = {
          active: true,
          address: "string",
          confirmPassword: this.cpassword,
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          password: this.password,
          phoneNumber: this.phone,
          promotionCode: this.promotionCode,
          referralCode: this.referralCode,
          url: window.location.origin,
        };
        let url = this.$store.state.base_url + "register";
        this.axios
          .post(url, data)
          .then((res) => {
            this.$refs.loginBtn.disabled = false;
            this.showPreloader = false;
            this.success = res.data.message;
            this.$router.push({
              name: "CustomerVerifyEmail",
              query: { email: this.email },
            });
          })
          .catch((err) => {
            this.$refs.loginBtn.disabled = false;
            this.showPreloader = false;
            this.error.empty = err.response.data.message;
          });
      }
    },
  },
  created() {
    // Access the query parameters from the URL
    const urlParams = new URLSearchParams(window.location.search);

    // Check if the referralCode parameter exists in the URL
    if (urlParams.has("referralCode")) {
      // Set the referral data property with the value from the URL
      this.referralCode = urlParams.get("referralCode");
      this.referralCodeInUrl = true
    }
  },
};
</script>