<template>
    <Header/>
    <div class="bg-gray-100">
        <Title name="Frequently Asked Questions(FAQs)"/>
        <div class=" mx-3 md:mx-16 py-16 grid grid-rows-1 gap-4">
            <div v-for="faq in faqs" :key="faq" class="border bg-white p-0 rounded-md">
                <div @click="toogleContent(faq.id)" class="py-6 px-4 border-b rounded-md flex justify-between">
                    <h3 class="font-semibold text-xl">{{ faq.name }}</h3>
                    <div :class="{'-rotate-90': faq.show == false}" class="transition duration-200 transform">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div v-show="faq.show" class="p-4 flex justify-between my-3">
                    {{ faq.content }}
                </div>
            </div>
        </div>
    </div>
    <Footer/>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Title from "@/components/Title.vue";
import BreadCrumb from "@/components/BreadCrumb.vue";
export default{
    name:"Contact",
    components:{
        Header,Footer,Title,BreadCrumb
    },
    data(){
        return{
            faqs:[
                {id:1,name:"Why Noshtrybe?", content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit ut dolorum suscipit ipsa ad. Eius, odit autem, eligendi ab repellat voluptatibus, error tempore et nulla velit totam molestias quod cum.", show:true},
                {id:2,name:"How schedule a meal", content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit ut dolorum suscipit ipsa ad. Eius, odit autem, eligendi ab repellat voluptatibus, error tempore et nulla velit totam molestias quod cum.", show:false}
            ]
        }
    },
    methods:{
        toogleContent(id){
            this.faqs.map(item =>{
                if(item.id == id){
                    item.show = !item.show
                }
            });
        }
    }
}
</script>

<style>

</style>