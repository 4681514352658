<template>
    <div class="flex h-screen w-full justify-center items-center bg-gray-50" >
        <div class="grid md:grid-cols-2 md:gap-4 mx-3 md:mx-auto " >
            <div class="flex items-center" >
                <div>
                    <h1 class="text-8xl">404</h1>
                    <p>We cant seem to find the page youre looking for</p>
                    <router-link to="/" class="text-brand font-semibold">Back to Homepage</router-link>
                </div>
               
            </div>
            <div class="mt-4 md:mt-0" >
                 <img src="@/assets/svg/lost.svg" class="h-96" alt="">
            </div>
        </div>
       
    </div>
  
</template>

<script>

</script>

<style>

</style>