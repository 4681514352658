<template>
<Header/>
<div class="">
  <div class=" mx-3 mt-10 lg:mx-16 lg:-mt-10">
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 bg-white lg:p-8" >
      <div class="flex items-center"  >
        <div>
          <h1  class="lg:text-5xl text-4xl font-semibold" >About Noshtrybe</h1>
          <p class="mt-4">Noshtrybe is a social food ordering platform re-defining the way people order for their meals online. Nosh means food and the concept of “Trybe” (same as tribe) in our name suggests a group of people who share common interest, value, culture, lifestyle, habits, language etc. </p>
        </div>
      </div>
      <div>
         <div class="flex justify-center">
            <img src="@/assets/img/about-2.png" alt="Noshtribe">
          </div> 
      </div>
    </div>
  </div>
  <div class="relative mt-12 lg:mt-0">
    <div class=" mx-3 lg:mx-16 py-16">
      <div class="grid lg:grid-cols-2 lg:gap-16 lg:p-8" >
       <div>
          <div class="flex justify-center">
            <img src="@/assets/img/about-1.png" alt="Noshtribe">
          </div> 
        </div>
        <div class="flex justify-center items-center">
          <div>
            <h1 class="lg:text-4xl text-2xl font-semibold" >We are Noshtrybe</h1>
            <p class="mt-2">Noshtrybe aims at encouraging people to form groups based on past or present relationship (eg friends at University, colleagues at work, members of the same faith etc).  As a social food platform, we facilitate engaging social connections and interactions among Trybe members so they get to discuss the meals they eat, rate the meals and even suggest meals to Trybe members.</p>
            <p class="mt-2">When Trybe members order meals on Noshtrybe we reward the Trybe with discounts and every member of the Trybe get cashbacks into their wallets by reason of being member of the Trybe. This explains our mantra: <span class="font-bold">You Eat</span> ,<span class="font-bold">We Pay</span></p>
          </div>
        </div>
      </div>  
    </div>
  </div>
  <div class="hidden">
    <div class="relative mt-12 lg:mt-0" >
      <div class="flex justify-center absolute -z-10 right-0" >
        <img src="@/assets/svg/left.svg" class="h-500" alt="Noshtribe">
      </div>
      <div class=" mx-3 lg:mx-16 py-16">
        <div class="grid md:grid-c lg:grid-cols-2 gap-8 lg:p-8" >
          <div class="flex items-center" >
            <div>
              <h1 class="text-2xl font-semibold" >Ordering as a Tribe</h1>
              <p class="mt-6" >By ordering meals as a tribe and inviting members of your tribe to your orders, thetribe gets a whopping discount not available from any restaurant or online food delivery platform.</p>
            </div>
          </div>
          <div>
            <div class="flex justify-start">
              <img class="h-300" src="@/assets/img/happy-joyful-friends-talking-laughing.png" alt="Noshtribe">
            </div> 
            <div class="flex mt-4 justify-end">
              <img class="h-300" src="@/assets/img/friends-drinking-eating-tailgate-party.png" alt="Noshtribe">
            </div> 
          </div>
        </div>  
      </div>
    </div>
  </div>
</div>

<Footer/>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from "@/components/Footer.vue";
export default {
  name: 'About',
  components: {
    Header,Footer,
  }
}
</script>
