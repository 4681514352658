<template>
<div id="gradient" class="top-0 fixed -z-10 h-screen w-full bg-gradient-to-b from-brand" ></div>
<div class="min-h-screen">
    <header class="lg:flex block lg:space-x-2 items-center flex-grow mx-3 py-6 md:mx-16">
        <div class="font-semibold text-base" >
            <router-link to="/">
                <img src="@/assets/img/logo.png" class="lg:h-20 h-16 w-auto" alt="">
            </router-link>
        </div>
    </header>
    <div class="lg:flex block lg:space-x-2 items-center flex-grow mx-3 h-500 md:mx-16" >
        <div class="lg:w-2/5 w-full flex items-center mt-4">
        <div class="" >
            <h1 class="md:text-5xl text-4xl  font-semibold" >Ever seen a <span class="font-bold" >platform</span> that pays you when you eat?</h1>
            <div class="text-xs text-gray-500 mt-4 ">
                <p class="font-semibold text-base">Be the first to be notified</p>
            </div>
        <div class="z-10 " >
                <div class="flex items-center justify-around border space-x-2 mt-4 bg-gray-100 h-14 pl-2 rounded-full w-full md:w-10/12">
                    <div class="flex items-center ">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                        </svg>
                    </div>
                    <div class="h-6 w-1 bg-gray-200 rounded-full" ></div>
                    <input type="email" v-model="email" class="w-full bg-transparent focus:outline-none rounded-r-full" placeholder="Enter your email address">
                </div>
                <div class="flex items-center justify-around border space-x-2 mt-4 bg-gray-100 h-14 pl-2 rounded-full w-full md:w-10/12">
                    <div class="flex items-center ">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                        </svg>
                    </div>
                    <div class="h-6 w-1 bg-gray-200 rounded-full" ></div>
                    <input type="email" v-model="phone" class="w-full bg-transparent focus:outline-none rounded-r-full" placeholder="Enter your phone number(Optional)">
                </div>
                <div class="mt-4 h-14 pl-2  md:w-10/12">
                    <button @click="submitEmail" :disabled="showPreloader" class="flex justify-center bg-brand border rounded-full w-full py-3">
                        <preloader-vue v-if="showPreloader"></preloader-vue>
                        <span v-else>Subscribe</span> 
                    </button>
                </div> 
            
        </div>            
        <p v-if="error" class="text-red-500 my-2">{{ error }}</p>
        </div>   
        </div>
        <div class="flex-grow flex lg:block flex-col-reverse mt-4 lg:mt-0" >
            <div class="flex justify-center items-start mb-6 lg:mb-0" >
                <img class="lg:w-768 h-auto w-auto"  src="@/assets/img/header.png" alt="Noshtribe">
            </div> 
            <div class="lg:flex justify-center block lg:space-x-2 items-center  lg:mt-0 flex-grow mx-3 py-6 md:mx-16" >
                <div>
                    <div class="text-gray-400 text-center lg:text-xl font-semibold" >And we promise the wait will not be too long</div>
                    <div class="flex justify-center" >
                        <vue-countdown :time="time"  v-slot="{ days, hours, minutes, seconds }">
                            <div class="flex space-x-6 my-6" >
                                <div>
                                    <div class="bg-brand p-4 rounded-lg">
                                        <p class="font-semibold text-2xl lg:text-5xl">{{ days }}</p>
                                    </div>
                                    <p class="text-center my-2">Days</p>
                                </div>
                                <div>
                                    <div class="bg-brand p-4 rounded-lg">
                                        <p class="font-semibold  text-2xl lg:text-5xl">{{ hours }}</p>
                                    </div>
                                    <p class="text-center my-2">Hours</p>
                                </div>
                                <div>
                                    <div class="bg-brand p-4 rounded-lg">
                                        <p class="font-semibold text-2xl lg:text-5xl">{{ minutes }}</p>
                                    </div>
                                    <p class="text-center my-2">Minutes</p>
                                </div>
                                <div>
                                    <div class="bg-brand p-4 rounded-lg">
                                        <p class="font-semibold text-2xl lg:text-5xl">{{ seconds }}</p>
                                    </div>
                                    <p class="text-center my-2">Seconds</p>
                                </div>
                            </div>
                        </vue-countdown>
                    </div>
                </div>
            </div>
        </div>    
    </div> 
</div>
<div v-if="success">
    <div class="fixed top-0">
        <Balloon/>
    </div>
    <div class="fixed flex justify-center items-center bg-overlay z-999 top-0 left-0 w-full h-full">
        <div class="bg-white rounded-md p-8 w-96 mx-3 animate__animated animate__jackInTheBox animate__fast">
            <div class="flex justify-center" >
                <img src="@/assets/img/clap.png" class="lg:h-20 h-16 w-auto" alt="">
            </div>
            <div class="text-center my-4">
                Thank you for joining our waiting list. We promise we won't be long!
            </div>
            <div class="my-4 flex items-start justify-center">
                <button @click="toggleSuccess" type="button" form="sort" class="bg-brand py-2 px-3 rounded text-black">
                    OK
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import moment from 'moment'
import VueCountdown from '@chenfengyuan/vue-countdown';
import 'animate.css';
import Balloon from '@/components/Balloon.vue'
import PreloaderVue from '../components/Preloader.vue';
export default {
  components:{
      VueCountdown,PreloaderVue,Balloon
  },
  data(){
     return{
        email: "",
        phone:"",
        error:"",
        showPreloader:false,
        launchDate: new Date('2022-04-25'),
        success:false
     }
  },
  computed:{
      time(){
          var diff = Math.abs(new Date() - this.launchDate);
          return diff
      }
  },
  methods:{
      toggleSuccess(){
          this.success = !this.success
      },
      async submitEmail(){
          let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
          let regexPhone = /^[0]\d{10}$/gm;
          if(!this.email){
              this.error = 'Email address is required'
          }else if(!this.email.match(regexEmail)){
              this.error = 'Invaild email address'
          }else if(this.phone && !this.phone.match(regexPhone)){
               this.error = 'Invaild phone number'
          }
          else{
              this.showPreloader = true;
              this.error = null;
              let url = this.$store.state.base_url + 'wait-list';
              let data = {
                    "address": "string",
                    "email": this.email,
                    "firstName": "string",
                    "lastName": "string",
                    "phone": (this.phone)? this.phone : "string",
                    "state": "string",
                    "vendor": false
              }
              try {
                  let res = await this.axios.post(url,data)
                  this.showPreloader = false;
                  this.email = this.phone = ""
                  this.toggleSuccess();
              } catch (error) {
                    if(error.response.data.statusCode == '400'){
                        this.error = error.response.data.message
                    }
                    
                   this.showPreloader = false;
              }

          }
      }
  }

}
</script>

<style>

</style>