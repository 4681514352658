<template>
   <div class="flex flex-col h-screen" >
        <div class="shadow-md">
            <Header/>
        </div>
        <div class="flex-1">
            <Auth  background="bg-white" height="h-full">
                <div class="lg:mx-14 mx-3 md:mx-7 py-16 md:py-0 w-full">
                        <div v-if="!success">
                            <h1 class="font-semibold text-4xl" >Forgot password</h1>
                            <p class="mt-4">Enter an e-mail associated with your account and we will send an email with an instructions to reset your password</p>
                            <form @submit.prevent="ResetPassword" class="mt-4">
                                <div v-if="error.empty" class="text-sm text-red-500 my-4" >{{ error.empty }}</div>
                                <div v-if="success" class="text-sm text-green-500 my-4" >{{ success }}</div>
                                <div class="md:grid md:grid-cols-2 md:gap-2 mt-4" >
                                    <div>
                                        <label for="email">Email Address</label>
                                        <input type="text" v-model="email" class="input" placeholder="Email Address">
                                    </div>
                                </div>
                                <div class="mt-4" >
                                    <button ref="loginBtn" class="bg-brand px-3 py-2 font-semibold rounded">
                                        <Preloader v-if="showPreloader"/>
                                        <span v-else >Reset password</span> 
                                    </button>
                                    <p class="flex-grow flex items-center justify-center md:justify-start mt-8" >Already have an account? <router-link :to="{ name: 'CustomerSignIn' }" class="text-brand ml-1">Login</router-link></p>
                                </div>
                            </form> 
                        </div>   
                        <div v-else>
                            <h1 class="font-semibold text-4xl" >Check E-mail</h1>
                            <p class="mt-4">We have sent a password recover instructions to your email.<br>Didn't receive email? Check your spam filter</p>
                            <button ref="loginBtn" @click="resend" class="bg-brand px-3 mt-4 py-2 font-semibold rounded">
                                Resend
                            </button>
                            <p class="mt-4"></p>
                        </div>    
                </div>
            </Auth>
        </div>
   </div>
</template>

<script>
import Auth from "@/components/admin/Auth.vue";

import Header from "@/components/Header.vue"; 
import Preloader from "@/components/Preloader.vue";
export default {
    name:['CustomerForgotPassword'],
    components:{
        Auth,Preloader,Header
    },
    data(){
    return{
            email:"",
            success:false,
            showPreloader:false,
            error:{
                email:null,
                empty:null,
            }
        }
    },
    methods: {
        ResetPassword(){
            let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(!this.email.match(regexEmail)){
                this.error.empty = "Invaild email address"
            }else{
                this.error.empty = null;
                this.showPreloader = true;
                let data = {
                    email: this.email,
                    url: window.location.origin
                }
                let url = this.$store.state.base_url + 'user/forgot-password'
                this.axios.post(url,data)
                .then((res)=>{
                    this.success = true;
                    this.showPreloader = false;

                }) 
                .catch((err)=>{
                    this.showPreloader =  false
                    this.error.empty = (err.response.data.statusCode == '404') ? "Email address do not exist" : null
                })

            }
        },
        resend(){
            this.success = false;
        }
    
    }
}
</script>

<style>
:root{
	--loader-width: 20px;
	--loader-height: 20px;
	--loader-color-primary: #000000;
	--loader-color-secondary: #eee;
	--line-width: 2px;
	--animation-duration: 2s;
	--loader-initial-scale: 0.1;
}
</style>