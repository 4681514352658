<template>
    <Header/>
        <div class="bg-gray-100 py-14">
            <div class="my-8">
                <Breadcrumb name="Ratings & Reviews"/>
            </div>
            <div class="mx-3 md:mx-16 mt-8">
                <h1 class="text-xl md:text-3xl font-semibold" >Thank you for rating the order</h1> 
                <div class="flex space-x-2 items-center"> 
                    <p class="">You just rated this order with </p>
                    <div class="mb-2">
                        <star-rating :increment="0.5" :rating="this.$route.params.star" inactive-color="#fff" border-color="#000" active-color="#DAA520" :border-width="2" :star-size="12" read-only=true :show-rating=false />
                    </div>
                    <p>({{ parseInt(this.$route.params.star).toFixed(1) }})</p>
                </div>
                <form @submit.prevent="submitReview" class="mt-8">
                    <h1 class="text-xl md:text-3xl font-semibold" >Kindly leave a Review</h1>
                    <div v-if="error" class="text-sm text-red-500 my-2">{{ error }}</div>
                    <div class="my-3">
                        <textarea class="input" placeholder="Title" cols="10" rows="2" v-model="title"></textarea>
                    </div>
                    <div class="my-3">
                        <textarea class="input" placeholder="Comment" cols="10" rows="8" v-model="comment"></textarea>
                    </div>
                    <div class="flex justify-end">
                        <button class="py-3 px-6 rounded text-sm text-right bg-brand" > 
                            <Preloader v-if="showPreloader"/>
                            <span v-else>Submit</span>
                        </button>
                    </div>
                </form>
            </div>  
        </div>
        <div v-if="showMsg" class="fixed flex justify-center items-center bg-overlay z-999 top-0 left-0 w-full h-full">
            <div class="bg-white rounded-md p-8 w-96 mx-3 animate__animated animate__jackInTheBox animate__fast">
                <div class="flex justify-center" >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-14 w-14 text-brand" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                       <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                    </svg>
                </div>
                <div class="text-center my-4">
                    Thank you for rating the order {{ this.$route.params.order }}!
                </div>
                <div class="my-4 flex justify-center">
                    <button @click="toRestaurants" type="button" form="sort" class="bg-brand py-2 px-3 rounded text-black">
                        OK
                    </button>
                </div>
        </div>
    </div>         
    <Footer/>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Breadcrumb from "@/components/BreadCrumb.vue";
import StarRating from 'vue-star-rating';

import Preloader from "@/components/Preloader.vue";
export default {
    name: 'Ratings',
    components:{
        Header,Footer,Breadcrumb,StarRating,Preloader
    },
    data(){
        return{
            title:"",
            comment:"",
            showPreloader:false,
            showMsg:false ,
            error:null      
        }
    },
    methods:{
        async submitReview(){
            let data = {
                "anonymous": false,
                "orderReferenceNumber": this.$route.params.order,
                "rating": parseInt(this.$route.params.star),
                "reviewComment": (this.comment) ? this.comment : "",
                "reviewTitle": (this.title) ? this.title : ""
            }
            this.error = null;
            try{
                this.showPreloader = true;
                let url = this.$store.state.base_url + 'review/public/'+ this.$route.params.order + '?rating=' + this.$route.params.star;
                let res = await this.axios.post(url,data);
                this.showPreloader = false;
                this.showMsg = true
            }catch(err){
                this.showPreloader = false;
                this.error = (err.response.status == 400) ? "You have already reviewed this order" : null
                console.log(err);
            }
        },
        toRestaurants(){
            this.$router.push('/restaurants')
        }
       
    },
    mounted(){
    }
}
</script>

<style>
:root{
	--loader-width: 20px;
	--loader-height: 20px;
	--loader-color-primary: #000000;
	--loader-color-secondary: #eee;
	--line-width: 2px;
	--animation-duration: 2s;
	--loader-initial-scale: 0.1;
}
</style>