<template>
    <Dashboard>
        <h1 class="text-2xl font-semibold">Account Setting</h1>
        <div class="bg-white p-6 rounded-md my-6">
            <form @submit.prevent="editProfileAction" class="mt-3">
                <div v-if="error.editProfile" class="text-sm text-red-500 mt-3">{{ error.editProfile }}</div>
                <div v-if="success.editProfile" class="text-sm text-green-500 mt-3">{{ success.editProfile }}</div>
                <div class="grid lg:grid-cols-2 gap-4 mb-3">
                    <div>
                        <label for="fullname" class="text-sm" >First Name</label>
                        <input type="text" v-model="editProfile.firstName" name="firstName" id="firstName"  class="input" placeholder="Enter First Name">
                    </div>
                    <div>
                        <label for="fullname" class="text-sm" >Last name</label>
                        <input type="text" v-model="editProfile.lastName" name="lastName"  id="lastName" class="input" placeholder="Enter Last Name">
                    </div>
                </div>
                <div class="grid lg:grid-cols-2 gap-4 mb-4">
                    <div class="" >
                        <label for="email" class="text-sm" >Email Address</label>
                        <input type="text" v-model="editProfile.email" name="email" id="email" class="input" placeholder="Enter email address" disabled>
                    </div>
                    <div class="" >
                        <label for="phone" class="text-sm" >Phone Number</label>
                        <input type="text" v-model="editProfile.phoneNumber" name="phone" id="phone" class="input" placeholder="08012345678">
                    </div>
                </div>
            </form>
             <div>
                <input type="checkbox" v-model="changePassword"/> <span class="text-sm">Check the box to enable change of password</span>
            </div>
            <form class="mt-3">
                <div v-if="error.changePassword" class="text-sm text-red-500 mt-3">{{ error.changePassword }}</div>
                <div v-if="success.changePassword" class="text-sm text-green-500 mt-3">{{ success.changePassword }}</div>
                <div>
                    <label for="password" class="text-sm" >Old password</label>
                    <input type="password" v-model="oldpassword" name="oldpassword"  id="oldpassword" class="input" placeholder="Enter old password">
                </div>
                <div class="grid lg:grid-cols-2 gap-4 my-3">
                    <div>
                        <label for="fullname" class="text-sm" >Password</label>
                        <input type="password" v-model="password" name="password" id="password"  class="input" placeholder="Enter New password">
                    </div>
                    <div>
                        <label for="fullname" class="text-sm" >Confirm password</label>
                        <input type="password" v-model="cpassword" name="cpassword"  id="cpassword" class="input" placeholder="Confirm password">
                    </div>
                </div>
            </form>
            <div class="my-4 flex-grow flex space-x-2 justify-end">
                <button @click="Update" ref="submit" class="bg-brand px-3 text-sm py-3 font-semibold rounded">
                    <Preloader v-if="showPreloader"/>
                    <span v-else >Update profile</span>      
                </button>
            </div>
        </div>
    </Dashboard>  
</template>

<script>
import Preloader from "@/components/Preloader.vue";
import Dashboard from '@/components/customers/Dashboard.vue'
import moment from 'moment'
export default {
    name:"OrderHistory",
    components:{
        Dashboard,Preloader
    },
    computed:{
        editProfile(){
            return this.$store.getters.getUserProfile;
        }
    },
    data(){
        return{
            showPreloader:false,
            error:{
                editProfile:null,
                changePassword:""
            },
            success:{
                editProfile:null,
                changePassword:""
            },
            tabs:{
                all:true,
                inprogress:false,
                delivered:false
            },
            oldpassword:"",
            password:"",
            cpassword:""

        }
    },
    methods:{
        formatNumber(num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        },
        shortenTime(date){
            return moment(date).format("MMM Do, YYYY",' h:mm:ss a'); 
        },
        datewithTime(date){
            return moment(date).format('lll');   
        },
        async GetUserProfile(){
            let url = this.$store.state.base_url + 'user-profile'; 
            let token = await this.$store.getters.getToken
            let res = await this.axios.get(url,{
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
            this.editProfile = res.data;   
        },
        Update(){
            if(this.changePassword){
                this.changePasswordAction();
            }else{
                this.editProfileAction();
            }
        },
        async changePasswordAction(){
            this.error.editProfile = this.success.editProfile = null
            if(!this.oldpassword || !this.password || !this.cpassword){
                this.error.changePassword = "All field are required"
            }else if(this.password.length < 6){
                this.error.changePassword = "Password too short";
            }
            else if(this.password != this.cpassword){
                this.error.changePassword = "Password do not match"
            }else{
                this.error.changePassword = null
                this.showPreloader = true;
                this.$refs.submit.disabled = true;
                try {
                    //Update password
                    let data = {
                        "confirmNewPassword": this.cpassword,
                        "newPassword": this.password,
                        "oldPassword": this.oldpassword 
                    }
                    let url = this.$store.state.base_url + 'user/change-password'
                    let token = await this.$store.getters.getToken
                    let res = await this.axios.post(url,data,{
                        headers: {
                            Authorization: 'Bearer ' + token
                        }
                    })
                    this.showPreloader = false;
                    this.$refs.submit.disabled = false;
                    this.success.changePassword = "Successfully changed password"
                    this.$store.dispatch('removeUserToken');
                    this.$store.dispatch('emptyCart')
                    this.$router.push({ name: 'CustomerSignIn' }) 
                } catch (error) {
                    this.$refs.submit.disabled = false;
                    this.showPreloader = false;
                    this.error.changePassword= err.response.data.message;
                }
            }
        },
        async editProfileAction(){
             this.error.changePassword = this.success.changePassword = null
            let regexPhone = /^[0]\d{10}$/gm;
            if(!this.editProfile.firstName || !this.editProfile.lastName || !this.editProfile.phoneNumber){
                this.error.editProfile = "All fields are required"
            }else if(!this.editProfile.phoneNumber.match(regexPhone)){
                this.error.editProfile = "Invaild phone number"
            }else{
                this.error.editProfile = null
                this.showPreloader = true;
                this.$refs.submit.disabled = true;
                //Update acction
                let url = this.$store.state.base_url + 'edit-user'
                let token = await this.$store.getters.getToken
                let res = await this.axios.put(url,{},{
                    headers: {
                        Authorization: 'Bearer ' +  token
                    },
                    params: {
                        firstName:this.editProfile.firstName,
                        lastName:this.editProfile.lastName,
                        phoneNumber:this.editProfile.phoneNumber
                    }
                })
                this.showPreloader = false;
                this.$refs.submit.disabled = false;
                this.success.editProfile = "Successfully updated your profile"
                let activeUser = this.$store.getters.getUser;
                activeUser.firstName = this.editProfile.firstName;
                var CryptoJS = require("crypto-js");
                var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(activeUser), process.env.VUE_APP_SECRET_PHRASE).toString();
                this.$store.dispatch('addUserToken',ciphertext);
                this.$emit('update')

            }
            
        }
    },
}
</script>
