<template>
    <div class="flex flex-col h-screen" >
        <div class="shadow-md">
            <Header/>
        </div>
        <div class="flex-1">
            <Auth  background="bg-white" height="h-full">
                <div class="md:mx-14 mx-3">
                    <h1 class="font-semibold text-4xl" >Thank you for confirming your account</h1>
                    <p class="mt-4">Your email has been verified. <router-link :to="{name:'CustomerSignIn'}" class="text-brand">Click here</router-link> to Sign in</p>
                </div>
            </Auth>
        </div>
    </div>
</template>
<script>
import Auth from "@/components/admin/Auth.vue";
import Header from "@/components/Header.vue"; 

import Preloader from "@/components/Preloader.vue";
export default {
    name:"EmailConfimation",
    components:{
        Auth,Preloader,Header
    },
    data(){
        return {
            token:(this.$route.query.token) ? this.$route.query.token : null
        }
    },
    methods:{
        activateAccount(){
            if(this.token){
                let url = this.$store.state.base_url + 'activate-account';
                this.axios.post(url,null,{
                    params:{
                        token:this.token
                    }
                })
            }else{

            }            
        }
    },
    created(){
     this.activateAccount()
    }
}
</script>