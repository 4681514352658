<template>
    <div class="flex flex-col h-screen" >
        <div class="shadow-md">
            <Header/>
        </div>
        <div class="flex-1">
            <Auth  background="bg-white" height="h-full">
                <div class="md:mx-14 mx-3">
                    <h1 class="font-semibold text-4xl" >Confirm your email address</h1>
                    <p class="mt-4">A confirmation email has been sent to your mailbox. Please click the link in the mail. If you do not find it in your inbox, Kindly check your spam and junk folder.</p>
                    <div v-if="error" class="text-sm text-red-500 my-4" >{{ error }}</div>
                    <div v-if="resendMsg" class="text-sm text-green-500 my-4" >{{ resendMsg }}</div>
                    <button @click="resendLink" class="bg-brand px-3 py-2 font-semibold rounded mt-3">
                        <Preloader v-if="showPreloader"/>
                        <span v-else > Resend email</span>
                    </button>
                </div>
            </Auth>
        </div>
    </div>


</template>

<script>
import Auth from "@/components/admin/Auth.vue";
import Header from "@/components/Header.vue";   

import Preloader from "@/components/Preloader.vue";
export default{
    name:"VerifyEmail",
    components:{
        Auth,Header,Preloader
    },
    data(){
        return{
            showPreloader:false,
            resendMsg:"",
            email:(this.$route.query.email)?this.$route.query.email:"",
            error:""
        }
    },
    methods:{
        resendLink(onload){
            let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(!this.email.match(regexEmail)){
                this.error = `Invaild email address`;
            }else{
                this.showPreloader = (onload !== 'onload') ? true : false;
                let url = this.$store.state.base_url + 'resend-activation-link'
                let data = {
                    email: this.email,
                    url: window.location.origin
                }
                this.axios.post(url,data)
                .then((res)=> {
                    if(onload !== 'onload'){
                        this.showPreloader = false;
                        this.resendMsg = "A new verification link has been sent to your E-mail Address" 
                        setTimeout(()=>{
                            //this.$router.push({name:"CustomerSignIn"});
                        },500)
                    }
        
                })
            }
        }
    },
    created(){
       if(this.$route.query.from === 'login'){
            this.resendLink("onload")
        }else if(!this.$route.query.email){
            this.$router.push({name:"CustomerSignUp"})
        }
    }
}
</script>