<template>
    <section class="flex flex-col h-screen fixed min-w-full">
        <div class="">
            <Header bg="bg-brand"/>
        </div>
        <div class="flex justify-center  flex-grow">
            <Sidebar :profile="profile"/>
            <div class="bg-gray-100 row-span-5 flex flex-grow relative items-stretch">
                <div class="flex-grow h-90vh overflow-scroll relative p-3 lg:p-8 lg:pb-2">
                    <slot></slot>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Header from '@/components/Header.vue';
import Sidebar from '@/components/customers/Sidebar.vue';
export default {
    name:"OrderHistory",
    components:{
        Header,Sidebar
    },
    data(){
        return{
            date:new Date().getFullYear()
        }
    },
    computed:{
        profile(){
            return this.$store.getters.getUserProfile;
        }
    }
}
</script>

<style>

</style>