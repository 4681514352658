<template>
  <Dashboard>
    <div v-if="viewDetails == false">
      <h1 class="lg:text-2xl text-xl font-semibold">Order History</h1>
      <div v-if="myOrders.length">
        <div class="lg:flex lg:justify-between items-center my-8">
          <div class="flex flex-wrap lg:gap-8 gap-4 text-gray-600 mb-4 lg:mb-0">
            <button
              :class="{ 'border-brand border-b-2': tabs.all }"
              @click="allOrders"
            >
              All Orders
            </button>
            <button
              :class="{ 'border-brand border-b-2': tabs.inprogress }"
              @click="filterdInprogressOrders"
            >
              In progress
            </button>
            <button
              :class="{ 'border-brand border-b-2': tabs.cancelled }"
              @click="filterdCancelledOrders"
            >
              Cancelled
            </button>
            <button
              :class="{ 'border-brand border-b-2': tabs.delivered }"
              @click="filterdDeliveredOrders"
            >
              Delivered
            </button>
            <button
              :class="{ 'border-brand border-b-2': tabs.archived }"
              @click="archievedOrders"
            >
              Archived
            </button>
          </div>
          <div>
            <input
              @input="sortOrder"
              v-model="search"
              class="input text-sm"
              type="text"
              placeholder="Search orders"
            />
          </div>
        </div>
        <div
          v-if="searchResult.length"
          class="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-4 mb-12"
        >
          <div
            v-for="order in searchResult"
            :key="order"
            class="shadow-md bg-white rounded-md py-4"
          >
            <div v-if="order.cartDto">
              <div class="flex justify-between">
                <h1 class="font-semibold px-4">
                  {{ order.orderReferenceNumber }}
                </h1>
                <div
                  v-if="order.status == 'ORDER_PLACED'"
                  class="bg-red-600 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Not paid
                </div>
                <div
                  v-else-if="order.status == 'ORDER_PAID'"
                  class="bg-indigo-800 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Paid
                </div>
                <div
                  v-else-if="order.status == 'ORDER_CONFIRMED'"
                  class="bg-green-600 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Confirmed
                </div>
                <div
                  v-else-if="order.status == 'ORDER_READY_FOR_PICKUP'"
                  class="bg-yellow-600 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Ready for pickup
                </div>
                <div
                  v-else-if="order.status == 'ORDER_SHIPPED'"
                  class="bg-blue-700 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Shipped
                </div>
                <div
                  v-else-if="order.status == 'ORDER_DELIVERED'"
                  class="bg-green-800 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Delivered
                </div>
                <div
                  v-else-if="order.status == 'ORDER_CANCELLED'"
                  class="bg-gray-800 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Cancelled
                </div>
                <div
                  v-else-if="order.status == 'ORDER_REFUNDED'"
                  class="bg-purple-800 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Refunded
                </div>
                <div
                  v-else-if="order.status == 'ORDER_REJECTED'"
                  class="bg-black text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Rejected
                </div>
                <div
                  v-else-if="order.status == 'ORDER_PREPARATION_IN_PROGRESS'"
                  class="bg-blue-500 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Processing
                </div>
                <div
                  v-else-if="order.status == 'ORDER_PREPARATION_COMPLETED'"
                  class="bg-purple-500 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Processed
                </div>
                <div
                  v-else-if="order.status == 'ORDER_ASSIGNED_TO_DRIVER'"
                  class="bg-indigo-400 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Assigned
                </div>
              </div>
              <div class="flex space-x-3 px-4 my-4">
                <div class="flex-shrink-0">
                  <img
                    :src="order.restaurantImage"
                    class="h-12 w-12 object-cover rounded-md"
                    alt=""
                  />
                </div>
                <div class="text-sm">
                  <h1>{{ order.restaurant.name }}</h1>
                  <p class="text-sm">
                    {{
                      order.cartDto.deliveryAddress
                        ? order.cartDto.deliveryAddress
                        : ""
                    }}
                  </p>
                </div>
              </div>
              <div class="grid grid-cols-5 gap-1 px-4 my-4 text-sm">
                <div class="text-gray-500 col-span-2">Item:</div>
                <div class="col-span-3">
                  {{
                    order.cartDto.foodCartItems
                      ? order.cartDto.foodCartItems.length
                      : 0 + order.cartDto.foodContainerCartItems
                      ? order.cartDto.foodContainerCartItems.length
                      : 0
                  }}
                  items
                </div>
                <div class="text-gray-500 col-span-2">Ordered Date:</div>
                <div class="col-span-3">
                  {{ datewithTime(order.createdAt) }}
                </div>
                <div class="text-gray-500 col-span-2">Expected Date:</div>
                <div class="col-span-3">
                  {{ shortenTime(order.cartDto.expectedDeliveryDate) }}
                </div>
                <div class="text-gray-500 col-span-2">Timebelt</div>
                <div class="col-span-3">
                  {{ order.cartDto.timeBelt.name }}
                </div>
                <div class="text-gray-500 col-span-2">Total</div>
                <div class="col-span-3">
                  &#8358; {{ formatNumber(order.totalCost) }}
                </div>
              </div>
              <div class="h-0.5 my-2 mx-4 bg-gray-100"></div>
              <div class="flex justify-center">
                <button
                  @click="viewDetailsOrder(order.id)"
                  class="font-semibold text-sm text-brand"
                >
                  VIEW DETAILS
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="filteredOrder.length"
          class="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-4 mb-12"
        >
          <div
            v-for="order in filteredOrder"
            :key="order"
            class="shadow-md bg-white rounded-md py-4"
          >
            <div v-if="order.cartDto">
              <div class="flex justify-between">
                <h1 class="font-semibold px-4">
                  {{ order.orderReferenceNumber }}
                </h1>
                <div
                  v-if="order.status == 'ORDER_PLACED'"
                  class="bg-red-600 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Not paid
                </div>
                <div
                  v-else-if="order.status == 'ORDER_PAID'"
                  class="bg-indigo-800 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Paid
                </div>
                <div
                  v-else-if="order.status == 'ORDER_CONFIRMED'"
                  class="bg-green-600 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Confirmed
                </div>
                <div
                  v-else-if="order.status == 'ORDER_READY_FOR_PICKUP'"
                  class="bg-yellow-600 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Ready for pickup
                </div>
                <div
                  v-else-if="order.status == 'ORDER_SHIPPED'"
                  class="bg-blue-700 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Shipped
                </div>
                <div
                  v-else-if="order.status == 'ORDER_DELIVERED'"
                  class="bg-green-800 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Delivered
                </div>
                <div
                  v-else-if="order.status == 'ORDER_CANCELLED'"
                  class="bg-gray-800 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Cancelled
                </div>
                <div
                  v-else-if="order.status == 'ORDER_REFUNDED'"
                  class="bg-purple-800 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Refunded
                </div>
                <div
                  v-else-if="order.status == 'ORDER_REJECTED'"
                  class="bg-black text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Rejected
                </div>
                <div
                  v-else-if="order.status == 'ORDER_PREPARATION_IN_PROGRESS'"
                  class="bg-blue-500 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Processing
                </div>
                <div
                  v-else-if="order.status == 'ORDER_PREPARATION_COMPLETED'"
                  class="bg-purple-500 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Processed
                </div>
                <div
                  v-else-if="order.status == 'ORDER_ASSIGNED_TO_DRIVER'"
                  class="bg-indigo-400 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Assigned
                </div>
              </div>
              <div class="flex space-x-3 px-4 my-4">
                <div class="flex-shrink-0">
                  <img
                    :src="order.restaurantImage"
                    class="h-12 w-12 object-cover rounded-md"
                    alt=""
                  />
                </div>
                <div class="text-sm">
                  <h1>{{ order.restaurant.name }}</h1>
                  <p class="text-sm">
                    {{
                      order.cartDto.deliveryAddress
                        ? order.cartDto.deliveryAddress
                        : ""
                    }}
                  </p>
                </div>
              </div>
              <div class="grid grid-cols-5 gap-1 px-4 my-4 text-sm">
                <div class="text-gray-500 col-span-2">Item:</div>
                <div class="col-span-3">
                  {{
                    getItemslength(
                      order.cartDto.foodCartItems,
                      order.cartDto.foodContainerCartItems
                    )
                  }}
                  items
                </div>
                <div class="text-gray-500 col-span-2">Ordered Date:</div>
                <div class="col-span-3">
                  {{ datewithTime(order.createdAt) }}
                </div>
                <div class="text-gray-500 col-span-2">Expected Date:</div>
                <div class="col-span-3">
                  {{ shortenTime(order.cartDto.expectedDeliveryDate) }}
                </div>
                <div class="text-gray-500 col-span-2">Timebelt</div>
                <div class="col-span-3">
                  {{ order.cartDto.timeBelt.name }}
                </div>
                <div class="text-gray-500 col-span-2">Total</div>
                <div class="col-span-3">
                  &#8358; {{ formatNumber(order.totalCost) }}
                </div>
              </div>
              <div class="h-0.5 my-2 mx-4 bg-gray-100"></div>
              <div class="flex justify-center">
                <button
                  @click="viewDetailsOrder(order.id)"
                  class="font-semibold text-sm text-brand"
                >
                  VIEW DETAILS
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="flex justify-center items-center h-96">
          <div>
            <div class="mx-auto flex justify-center text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-14 w-14"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                />
              </svg>
            </div>
            <p class="text-sm">{{ msg }}</p>
          </div>
        </div>
        <div
          v-if="page + 1 != totalPage"
          class="flex flex-grow justify-center mb-6"
        >
          <div class="flex items-center gap-3">
            <button
              @click="loadMore"
              class="py-4 rounded-md px-8 text-sm bg-brand"
            >
              <Preloader v-if="showLoadmorePreloader" />
              <span v-else>Load more</span>
            </button>
          </div>
        </div>
      </div>
      <div v-else class="flex justify-center items-center h-96">
        <div v-if="msg == 'Fetching orders ...'" class="flex space-x-3">
          <Preloader />
          <p class="text-sm">{{ msg }}</p>
        </div>
        <div v-else>
          <div class="mx-auto flex justify-center text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-14 w-14"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <p class="text-sm">{{ msg }}</p>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="lg:flex lg:justify-between">
        <h1 class="text-2xl font-semibold">Order Details</h1>
        <div class="flex space-x-3 mt-4 lg:mt-0">
          <router-link
            :to="{ name: 'Resturants' }"
            class="text-sm bg-brand rounded-md font-semibold py-3 px-6"
            >Place your order</router-link
          >
          <button
            @click="clearViewDetails"
            class="text-sm bg-brand rounded-md py-3 px-6"
          >
            Order history
          </button>
        </div>
      </div>
      <div class="grid lg:grid-cols-5 gap-6 my-8">
        <div class="lg:col-span-3">
          <div class="bg-white rounded-md shadow-md py-6">
            <div class="flex justify-between">
              <div class="grid grid-cols-5 w-3/4 gap-1 px-4 mb-6 text-sm">
                <div class="font-semibold col-span-2">Order ID:</div>
                <div class="col-span-3">
                  <p class="font-semibold">
                    {{ viewDetailsOrderData.orderReferenceNumber }}
                  </p>
                </div>
                <div class="text-gray-500 col-span-2">Restaurant Name</div>
                <div class="col-span-3">
                  {{ viewDetailsOrderData.restaurant.name }}
                </div>
                <div class="text-gray-500 col-span-2">Ordered Date:</div>
                <div class="col-span-3">
                  {{ datewithTime(viewDetailsOrderData.createdAt) }}
                </div>
                <div class="text-gray-500 col-span-2">Expected Date:</div>
                <div class="col-span-3">
                  {{ shortenTime(viewDetailsOrderData.cartDto.deliveryDate) }}
                </div>
                <div class="text-gray-500 col-span-2">Timebelt</div>
                <div class="col-span-3">
                  {{ viewDetailsOrderData.cartDto.timeBelt.name }}
                </div>
                <div class="text-gray-500 col-span-2">Cashback</div>
                <div class="col-span-3">
                  &#8358;{{
                    viewDetailsOrderData.amountDiscounted
                      ? formatNumber(viewDetailsOrderData.totalCost)
                      : 0
                  }}.00
                </div>
              </div>
              <div class="flex items-start">
                <div
                  v-if="viewDetailsOrderData.status == 'ORDER_PLACED'"
                  class="bg-red-600 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Not paid
                </div>
                <div
                  v-else-if="viewDetailsOrderData.status == 'ORDER_PAID'"
                  class="bg-indigo-800 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Paid
                </div>
                <div
                  v-else-if="viewDetailsOrderData.status == 'ORDER_CONFIRMED'"
                  class="bg-green-600 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Confirmed
                </div>
                <div
                  v-else-if="
                    viewDetailsOrderData.status == 'ORDER_READY_FOR_PICKUP'
                  "
                  class="bg-yellow-600 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Ready for pickup
                </div>
                <div
                  v-else-if="viewDetailsOrderData.status == 'ORDER_SHIPPED'"
                  class="bg-blue-700 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Shipped
                </div>
                <div
                  v-else-if="viewDetailsOrderData.status == 'ORDER_DELIVERED'"
                  class="bg-green-800 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Delivered
                </div>
                <div
                  v-else-if="viewDetailsOrderData.status == 'ORDER_CANCELLED'"
                  class="bg-gray-800 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Cancelled
                </div>
                <div
                  v-else-if="viewDetailsOrderData.status == 'ORDER_REFUNDED'"
                  class="bg-purple-800 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Refunded
                </div>
                <div
                  v-else-if="viewDetailsOrderData.status == 'ORDER_REJECTED'"
                  class="bg-black text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Rejected
                </div>
                <div
                  v-else-if="
                    viewDetailsOrderData.status ==
                    'ORDER_PREPARATION_IN_PROGRESS'
                  "
                  class="bg-blue-500 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Processing
                </div>
                <div
                  v-else-if="
                    viewDetailsOrderData.status == 'ORDER_PREPARATION_COMPLETED'
                  "
                  class="bg-purple-500 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Processed
                </div>
                <div
                  v-else-if="
                    viewDetailsOrderData.status == 'ORDER_ASSIGNED_TO_DRIVER'
                  "
                  class="bg-indigo-400 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  Assigned
                </div>
              </div>
            </div>
            <div class="flex flex-col gap-3">
              <div v-if="viewDetailsOrderData.cartDto.foodContainerCartItems">
                <div class="flex flex-col text-sm gap-3">
                  <div
                    v-for="container in viewDetailsOrderData.cartDto
                      .foodContainerCartItems"
                    :key="container"
                    class="p-3"
                  >
                    <div class="flex justify-between mb-3">
                      <p class="font-semibold text-sm">
                        {{ container.foodContainerName }}
                      </p>
                    </div>
                    <div class="flex flex-col gap-3">
                      <div
                        v-for="cart in container.foodCartItems.filter(
                          (item) => item.quantity != 0
                        )"
                        :key="cart"
                      >
                        <div class="flex gap-3">
                          <div>
                            <img
                              :src="cart.foodImage"
                              class="h-14 w-14 object-cover rounded-md"
                              alt=""
                            />
                          </div>
                          <div class="flex-grow flex justify-between">
                            <div>
                              <h5 class="my-2 text-sm">
                                <span>{{ cart.foodName }}</span>
                              </h5>
                              <div class="text-sm">Qty:{{ cart.quantity }}</div>
                            </div>
                            <div class="text-sm my-2 text-right">
                              &#8358;{{ this.global.formatNumber(cart.price) }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex gap-3 mt-3">
                      <div>
                        <img
                          :src="container.foodContainerImage"
                          class="h-14 w-14 object-cover rounded-md"
                          alt=""
                        />
                      </div>
                      <div class="flex-grow flex justify-between">
                        <div>
                          <h5 class="my-2 text-sm">
                            <span>{{ container.foodContainerName }}</span>
                          </h5>
                          <div class="text-sm">
                            Qty:{{ container.quantity }}
                          </div>
                        </div>
                        <div class="text-sm my-2 text-right">
                          &#8358;{{
                            this.global.formatNumber(container.containerPrice)
                          }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="flex border-t mt-3 text-sm pt-2 justify-between"
                    >
                      <div class="text-sm text-gray-500">
                        x{{ container.quantity }} items
                      </div>
                      <div class="text-right">
                        Total: &#8358;{{
                          this.global.formatNumber(container.totalPrice)
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="viewDetailsOrderData.cartDto.foodCartItems"
                class="p-3"
              >
                <p class="font-semibold text-sm mb-4">Combo Meals</p>
                <div class="flex flex-col gap-3">
                  <div
                    v-for="cart in viewDetailsOrderData.cartDto.foodCartItems.filter(
                      (item) => item.quantity != 0
                    )"
                    :key="cart"
                  >
                    <div class="flex gap-3">
                      <div>
                        <img
                          :src="cart.foodImage"
                          class="h-14 w-14 object-cover rounded-md"
                          alt=""
                        />
                      </div>
                      <div class="flex-grow flex justify-between">
                        <div>
                          <h5 class="my-2 text-sm">
                            <span>{{ cart.foodName }}</span>
                          </h5>
                          <div class="text-sm">Qty:{{ cart.quantity }}</div>
                        </div>
                        <div class="text-sm my-2 text-right">
                          &#8358;{{ this.global.formatNumber(cart.price) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="border-t mt-3 text-sm pt-2 text-right">
                  Total: &#8358;{{ this.global.formatNumber(getComboTotal()) }}
                </div> -->
              </div>
            </div>
            <div
              class="flex text-sm justify-between items-center px-4 mt-8 mb-3"
            >
              <div class="text-gray-500">Subtotal</div>
              <div class="text-gray-500">
                &#8358;{{ this.global.formatNumber(this.getSubTotal()) }}
              </div>
            </div>
            <div class="flex text-sm justify-between items-center px-4 mb-3">
              <div class="text-gray-500">Delivery fee</div>
              <div class="text-gray-500">
                &#8358;{{ viewDetailsOrderData.shippingCost }}
              </div>
            </div>
            <div
              v-if="viewDetailsOrderData.serviceCharge"
              class="flex text-sm justify-between items-center px-4 mb-3"
            >
              <div class="text-gray-500">Service fee</div>
              <div class="text-gray-500">
                &#8358;{{ viewDetailsOrderData.serviceCharge }}
              </div>
            </div>
            <div class="flex text-sm justify-between items-center px-4 mb-3">
              <div class="text-gray-500">Total</div>
              <div class="text-gray-500">
                &#8358;{{ formatNumber(viewDetailsOrderData.totalCost) }}
              </div>
            </div>
            <div
              class="flex text-sm justify-between items-center px-4 mb-3"
              v-if="
                viewDetailsOrderData.status == 'ORDER_PLACED' &&
                checkTimebeltAvailable()
              "
            >
              <div class="text-gray-500">Wallet Balance</div>
              <div class="text-gray-500">
                &#8358;{{ formatNumber(parseInt(profile.walletBalance) ?? 0) }}
              </div>
            </div>

            <div
              class="flex text-sm justify-between items-center px-4"
              v-if="
                viewDetailsOrderData.status == 'ORDER_PLACED' &&
                checkTimebeltAvailable() &&
                viewDetailsOrderData.totalCost > profile.walletBalance
              "
            >
              <div class="text-gray-500 font-bold">Amount to Pay</div>
              <div class="font-bold">
                &#8358;{{
                  formatNumber(
                    (
                      viewDetailsOrderData.totalCost -
                      (parseInt(profile.walletBalance) ?? 0)
                    ).toFixed(2)
                  )
                }}
              </div>
            </div>
            <div
              v-if="
                viewDetailsOrderData.status == 'ORDER_PLACED' &&
                checkTimebeltAvailable()
              "
              @click="payForOrder"
              class="flex justify-end px-4 my-4"
            >
              <button
                class="py-2 px-4 font-semibold text-sm rounded-md border bg-brand"
              >
                {{ payBtnText }}
              </button>
            </div>
          </div>
        </div>
        <div class="lg:col-span-2 bg-white rounded-md shadow-md py-6">
          <div class="flex justify-between px-4 items-center">
            <div class="text-sm font-semibold">
              Track order ({{ viewDetailsOrderData.orderReferenceNumber }})
            </div>
            <div
              v-if="
                viewDetailsOrderData.status == 'ORDER_DELIVERED' &&
                viewDetailsOrderData.orderReveiwId == null
              "
            >
              <button
                @click="toggleShowReview"
                class="text-sm bg-brand rounded-md py-2 px-4"
              >
                Rate your order
              </button>
            </div>
          </div>
          <TrackOrder :singleOrder="viewDetailsOrderData" />
        </div>
      </div>
    </div>
  </Dashboard>
  <Modal :show="true" v-if="showReview" width="w-500">
    <div class="flex justify-between items-center top-0 bg-white pb-2">
      <p class="text-xl font-semibold">Rate your order</p>
      <div class="" @click="toggleShowReview">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
    <div class="mb-3">
      <p class="text-sm mb-4">Tell us what you think about the order</p>
      <star-rating
        :increment="1"
        v-model:rating="rating"
        inactive-color="#fff"
        padding="4"
        border-color="#000"
        active-color="#DAA520"
        :show-rating="false"
        :border-width="2"
        :star-size="18"
      />
    </div>
    <form @submit.prevent="submitReview" class="">
      <div v-if="error" class="text-sm text-red-500 my-2">{{ error }}</div>
      <div class="my-3">
        <input type="text" class="input" v-model="title" placeholder="Title" />
      </div>
      <div class="my-3">
        <textarea
          class="input"
          placeholder="Comment"
          cols="10"
          rows="8"
          v-model="comment"
        ></textarea>
      </div>
      <div class="flex justify-end">
        <button class="py-3 px-6 rounded text-sm text-right bg-brand">
          <Preloader v-if="showRatingPreloader" />
          <span v-else>Submit</span>
        </button>
      </div>
    </form>
  </Modal>
</template>

<script>
import Dashboard from "@/components/customers/Dashboard.vue";
import TrackOrder from "@/components/customers/TrackOrder.vue";
import Preloader from "@/components/Preloader.vue";
import moment from "moment";
import Modal from "@/components/Modal.vue";
import StarRating from "vue-star-rating";
export default {
  name: "OrderHistory",
  components: {
    Dashboard,
    TrackOrder,
    Preloader,
    Modal,
    StarRating,
  },
  data() {
    return {
      myOrders: [],
      filteredOrder: [],
      tabs: {
        all: true,
        inprogress: false,
        delivered: false,
        cancelled: false,
        archived: false,
      },
      search: "",
      payBtnText: "Pay Now",
      viewDetails: false,
      viewDetailsOrderData: {},
      searchResult: [],
      amountToPayWithWallet: 0,
      msg: "Fetching orders ...",
      paykey: process.env.VUE_APP_PAYSTACK_KEY,
      showReview: false,
      rating: 0,
      error: null,
      title: "",
      comment: "",
      showRatingPreloader: false,
      page: 0,
      size: 6,
      showLoadmorePreloader: false,
      totalPage: 0,
    };
  },
  computed: {
    profile() {
      return this.$store.getters.getUserProfile;
    },
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    getSubTotal() {
      let comboCost = this.getComboTotal();
      let containers = this.viewDetailsOrderData.cartDto.foodContainerCartItems;
      let containersCost = 0;
      if (containers) {
        containers.forEach((item) => {
          containersCost = containersCost + item.totalPrice;
        });
      }
      let subTotal = containersCost + comboCost;
      return subTotal;
    },
    async getOrderHistory() {
      let url = this.$store.state.base_url + "order/my-orders";
      try {
        let token = await this.$store.getters.getToken;
        let res = await this.axios.get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: {
            page: this.page,
            size: this.size,
          },
        });
        this.totalPage = res.data.totalPages;
        if (res.data.content.length) {
          res.data.content.forEach((item) => {
            this.filteredOrder.push(item);
          });
          this.myOrders = this.filteredOrder;
          this.filteredOrder = this.myOrders.filter((item) => {
            if (
              item.status == "ORDER_PLACED" &&
              (moment(new Date(item.cartDto.deliveryDate)).format("L") >
                moment().format("L") ||
                (moment(new Date(item.cartDto.deliveryDate)).format("L") ==
                  moment().tz("Africa/Lagos").format("L") &&
                  this.timebeltRestrictionItem(item) >
                    moment().tz("Africa/Lagos").format("HH:mm:ss")))
            ) {
              return item;
            } else if (
              item.status == "ORDER_PAID" ||
              item.status == "ORDER_CONFIRMED" ||
              item.status == "ORDER_READY_FOR_PICKUP" ||
              item.status == "ORDER_SHIPPED" ||
              item.status == "ORDER_PREPARATION_IN_PROGRESS" ||
              item.status == "ORDER_PREPARATION_COMPLETED" ||
              item.status == "ORDER_ASSIGNED_TO_DRIVER" ||
              item.status == "ORDER_CANCELLED" ||
              item.status == "ORDER_REFUNDED" ||
              item.status == "ORDER_REJECTED" ||
              item.status == "ORDER_DELIVERED"
            ) {
              return item;
            }
          });
          this.msg = "No order found";
        } else {
          this.filteredOrder = this.myOrders = [];
          this.msg = "No order found";
        }
      } catch (error) {
        this.msg = "Please try again";
        console.log(error);
      }
    },
    shortenTime(date) {
      return moment(date).format("MMM Do, YYYY", " h:mm:ss a");
    },
    datewithTime(date) {
      return moment(date).format("lll");
    },
    filterdDeliveredOrders() {
      this.filteredOrder = this.myOrders.filter(
        (item) => item.status == "ORDER_DELIVERED"
      );
      this.tabs.delivered = true;
      this.tabs.all =
        this.tabs.inprogress =
        this.tabs.archived =
        this.tabs.cancelled =
          false;
      this.searchResult = [];
    },
    filterdInprogressOrders() {
      this.filteredOrder = this.myOrders.filter(
        (item) =>
          item.status == "ORDER_CONFIRMED" ||
          item.status == "ORDER_READY_FOR_PICKUP" ||
          item.status == "ORDER_SHIPPED" ||
          item.status == "ORDER_PREPARATION_IN_PROGRESS" ||
          item.status == "ORDER_PREPARATION_COMPLETED" ||
          item.status == "ORDER_ASSIGNED_TO_DRIVER"
      );
      this.tabs.inprogress = true;
      this.tabs.all =
        this.tabs.delivered =
        this.tabs.archived =
        this.tabs.cancelled =
          false;
      this.searchResult = [];
    },
    filterdCancelledOrders() {
      this.filteredOrder = this.myOrders.filter(
        (item) =>
          item.status == "ORDER_CANCELLED" ||
          item.status == "ORDER_REFUNDED" ||
          item.status == "ORDER_REJECTED"
      );
      this.tabs.cancelled = true;
      this.tabs.all =
        this.tabs.delivered =
        this.tabs.archived =
        this.tabs.inprogress =
          false;
      this.searchResult = [];
    },
    filterdAllOrders() {
      this.filteredOrder = this.myOrders.filter(
        (item) =>
          item.status == "ORDER_PLACED" && item.createdAt > moment().format()
      );
      this.tabs.cancelled = true;
      this.tabs.all =
        this.tabs.archived =
        this.tabs.delivered =
        this.tabs.inprogress =
          false;
      this.searchResult = [];
    },
    allOrders() {
      this.filteredOrder = this.myOrders.filter((item) => {
        if (
          item.status == "ORDER_PLACED" &&
          (moment(new Date(item.cartDto.deliveryDate)).format("L") >
            moment().format("L") ||
            (moment(new Date(item.cartDto.deliveryDate)).format("L") ==
              moment().tz("Africa/Lagos").format("L") &&
              this.timebeltRestrictionItem(item) >
                moment().tz("Africa/Lagos").format("HH:mm:ss")))
        ) {
          return item;
        } else if (
          item.status == "ORDER_PAID" ||
          item.status == "ORDER_CONFIRMED" ||
          item.status == "ORDER_READY_FOR_PICKUP" ||
          item.status == "ORDER_SHIPPED" ||
          item.status == "ORDER_PREPARATION_IN_PROGRESS" ||
          item.status == "ORDER_PREPARATION_COMPLETED" ||
          item.status == "ORDER_ASSIGNED_TO_DRIVER" ||
          item.status == "ORDER_CANCELLED" ||
          item.status == "ORDER_REFUNDED" ||
          item.status == "ORDER_REJECTED" ||
          item.status == "ORDER_DELIVERED"
        ) {
          return item;
        }
      });
      this.tabs.all = true;
      this.tabs.delivered =
        this.tabs.inprogress =
        this.tabs.archived =
        this.tabs.cancelled =
          false;
      this.searchResult = [];
    },
    archievedOrders() {
      this.filteredOrder = this.myOrders.filter((item) => {
        if (
          item.status == "ORDER_PLACED" &&
          (moment(new Date(item.cartDto.deliveryDate)).format("L") <
            moment().format("L") ||
            (moment(new Date(item.cartDto.deliveryDate)).format("L") ==
              moment().tz("Africa/Lagos").format("L") &&
              this.timebeltRestrictionItem(item) <
                moment().tz("Africa/Lagos").format("HH:mm:ss")))
        ) {
          return item;
        }
      });
      this.tabs.archived = true;
      this.tabs.delivered =
        this.tabs.all =
        this.tabs.inprogress =
        this.tabs.cancelled =
          false;
      this.searchResult = [];
    },
    toogleViewDetail() {
      this.viewDetails = !this.viewDetails;
    },
    viewDetailsOrder(id) {
      this.viewDetailsOrderData = this.myOrders.find((item) => item.id == id);
      this.toogleViewDetail();
      window.scrollTo(0, 0);
    },
    clearViewDetails() {
      this.viewDetailsOrderData = "";
      this.toogleViewDetail();
    },
    sortOrder() {
      if (this.search) {
        this.searchResult = this.filteredOrder.filter(
          (col) =>
            col.orderReferenceNumber
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            col.restaurant.name
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            col.cartDto.timeBelt.name
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            col.cartDto.foodCartItems.some(
              (meal) => meal.foodName.toLowerCase() == this.search.toLowerCase()
            )
        );
      } else {
        this.searchResult = [];
      }
    },
    checkTimebeltAvailable() {
      if (
        moment(
          new Date(this.viewDetailsOrderData.cartDto.expectedDeliveryDate)
        ).format("L") > moment().format("L")
      ) {
        return true;
      } else if (
        moment(
          new Date(this.viewDetailsOrderData.cartDto.expectedDeliveryDate)
        ).format("L") == moment().tz("Africa/Lagos").format("L")
      ) {
        //checking timebelt availablty
        if (
          moment().tz("Africa/Lagos").format("HH:mm:ss") <
          this.timebeltRestriction()
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    timebeltRestrictionItem(item) {
      let hourLimit = this.convertTimebelt(item.cartDto.timeBelt.endTime);
      let window = item.cartDto.timeBelt.deliveryWindow;
      return this.restrictionTime(hourLimit, window);
    },
    timebeltRestriction() {
      let hourLimit = this.convertTimebelt(
        this.viewDetailsOrderData.cartDto.timeBelt.endTime
      );
      let timebeltName = this.viewDetailsOrderData.cartDto.timeBelt.name;
      let window = this.viewDetailsOrderData.cartDto.timeBelt.deliveryWindow;
      return this.restrictionTime(hourLimit, window);
    },
    convertTimebelt(time) {
      time.replace(/\s+/g);
      let hours = time.match(/\d+/)[0];
      let string = time.replace(/\d+/, "");
      hours = string.toLowerCase() == "pm" ? parseInt(hours) + 12 : hours;
      return hours;
    },
    restrictionTime(hours, mins) {
      let restrictionTime = moment(`${hours}:00 :00`, "HH:mm:ss");
      return moment(restrictionTime, "HH:mm:ss")
        .subtract(mins, "minutes")
        .format("HH:mm:ss");
    },
    async payForOrder() {
      if (this.checkTimebeltAvailable() == true) {
        this.payBtnText = "Paying order...";
        try {
          let data = {
            additionalInformation: this.viewDetailsOrderData.additionInfo
              ? this.viewDetailsOrderData.additionInfo
              : "",
            deliveryName: this.viewDetailsOrderData.contactPersonName
              ? this.viewDetailsOrderData.contactPersonName
              : `${this.profile.firstName} ${this.profile.lastName}`,
            deliveryPhone: this.viewDetailsOrderData.phoneNumber
              ? this.viewDetailsOrderData.phoneNumber
              : this.profile.phoneNumber,
            orderId: this.viewDetailsOrderData.id,
          };

          let url = this.$store.state.base_url + "payment/process";
          let token = await this.$store.getters.getToken;
          let res = await this.axios.post(url, data, {
            headers: {
              Authorization: "Bearer " + token,
            },
          });
          let paymentRef = res.data.paymentReferenceNumber;
          let OrderID = res.data.orderId;
          let payurl = this.$store.state.base_url + `payment/pay/${OrderID}`;
          await this.axios.post(payurl, null, {
            headers: {
              Authorization: "Bearer " + token,
            },
          });
          this.amountToPayWithWallet = res.data.amountToPayWithWallet;
          this.profile.walletBalance =
            parseInt(this.profile.walletBalance) -
            parseInt(res.data.amountToPayWithWallet);
          this.$store.dispatch("addUserProfile", this.profile);
          if (res.data.amountToPayWithPaymentGateway > 0) {
            let totalPaystackCost = res.data.amountToPayWithPaymentGateway;
            this.payWithPaystack(totalPaystackCost, paymentRef);
          } else {
            this.payWithWallet(res);
          }
        } catch (error) {
          this.payBtnText = "Pay Now";
        }
      }
    },
    payWithPaystack(totalPaystackCost, paymentRef) {
      var self = this; // assign context to self variable
      require("../../paystack/index.js");
      let paykey = this.paykey;
      let handler = PaystackPop.setup({
        key: paykey,
        email: self.profile.email,
        amount: totalPaystackCost * 100,
        ref: paymentRef,
        onClose: function () {
          self.checkoutonclose(paymentRef, self);
        },
        callback: function (response) {
          self.confirmed();
        },
      });
      handler.openIframe();
    },
    async payWithWallet() {
      let url =
        this.$store.state.base_url + `order/${this.viewDetailsOrderData.id}`;
      let token = await this.$store.getters.getToken;
      await this.axios.get(url, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      this.confirmed();
      this.payBtnText = "Pay Now";
    },
    async checkoutonclose(paymentRef, self) {
      this.payBtnText = "Pay Now";
      this.profile.walletBalance =
        parseInt(this.profile.walletBalance) +
        parseInt(this.amountToPayWithWallet);
      this.$store.dispatch("addUserProfile", this.profile);
      let url = self.$store.state.base_url + "payment/paystack/on-close";
      let token = await self.$store.getters.getToken;
      await this.axios.post(url, null, {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          paymentReference: paymentRef,
        },
      });
    },
    confirmed() {
      this.myOrders.map((item) => {
        if (item.id == this.viewDetailsOrderData.id) {
          item.status = "ORDER_PAID";
          item.orderPaidDate = new Date().toISOString();
        }
      });
      this.viewDetailsOrderData.status = "ORDER_PAID";
    },
    toggleShowReview() {
      this.showReview = !this.showReview;
      this.rating = 0;
    },
    async submitReview() {
      let data = {
        anonymous: false,
        orderReferenceNumber: this.viewDetailsOrderData.orderReferenceNumber,
        rating: parseInt(this.rating),
        reviewComment: this.comment ? this.comment : "",
        reviewTitle: this.title ? this.title : "",
      };
      this.error = null;
      try {
        this.showRatingPreloader = true;
        let url =
          this.$store.state.base_url +
          "review/public/" +
          this.viewDetailsOrderData.orderReferenceNumber +
          "?rating=" +
          parseInt(this.rating);
        await this.axios.post(url, data);
        this.myOrders.map((item) => {
          if (item.id == this.viewDetailsOrderData.id) {
            item.orderReveiwId = 90;
          }
        });
        this.toggleShowReview();
        this.viewDetailsOrderData.orderReveiwId = 90;
        this.showRatingPreloader = false;
      } catch (err) {
        this.showRatingPreloader = false;
        this.error =
          err.response.status == 400
            ? "You have already reviewed this order"
            : null;
        console.log(err);
      }
    },
    getItemslength(foodCartItems, foodContainerCartItems) {
      let comboLength = foodCartItems ? foodCartItems.length : 0;
      let ContainerLength = foodContainerCartItems
        ? foodContainerCartItems.length
        : 0;

      return comboLength + ContainerLength;
    },
    async loadMore() {
      this.showLoadmorePreloader = true;
      this.page++;
      await this.getOrderHistory();
      this.showLoadmorePreloader = false;
    },
    getComboTotal() {
      let cost = 0;
      if (this.viewDetailsOrderData.cartDto.foodCartItems) {
        this.viewDetailsOrderData.cartDto.foodCartItems.forEach((element) => {
          cost = cost + element.price;
        });
      }

      return cost;
    },
  },
  mounted() {
    this.getOrderHistory();
  },
};
</script>