<template>
    <Dashboard>
        <h1 class="lg:text-2xl text-xl my-6 lg:my-0 px-3 lg:px-0 font-semibold">Wallet History</h1>
        <div class="lg:flex lg:justify-center my-8 lg:space-x-4">
            <div class="flex space-x-4 my-4 items-center lg:w-64 bg-white border border-gray-300 rounded-md p-3" >
                <div class="h-12 w-12 bg-brand flex justify-center items-center rounded-full">
                    <img src="@/assets/svg/earning.svg" class="h-6 w-6" alt="">
                </div>
                <div class="flex items-center" >
                    <div>
                        <p class="font-semibold">&#8358; {{ (profile.walletBalance) ? formatNumber(profile.walletBalance) :'0.00' }}</p>
                        <p class="text-xs">Available Balance</p>
                    </div>     
                </div>
            </div> 
            <div class="flex space-x-4 my-4 items-center lg:w-64 bg-white border border-gray-300 rounded-md p-3" >
                <div class="h-12 w-12 bg-yellow-400 flex justify-center items-center rounded-full">
                    <img src="@/assets/svg/earning.svg" class="h-6 w-6" alt="">
                </div>
                <div class="flex items-center" >
                    <div>
                        <p class="font-semibold">&#8358;{{ (profile.walletBookBalance) ? formatNumber(profile.walletBookBalance) : "0.00" }}</p>
                        <p class="text-xs">Book Balance</p>
                    </div>     
                </div>
            </div> 
          
        </div>

        <div class="flex justify-center my-6">
            <div class="lg:w-2/5 w-full">   
                <div class=" bg-white border-brand border p-2 rounded-md">
                    <form @submit.prevent="fundWallet" class="flex space-x-3">
                        <input type="text" v-model="amount" class="bg-transparent outline-none w-3/5 px-2 text-sm"  name="" id="" placeholder="Enter Amount">
                        <button type="submit" class="text-sm flex justify-center bg-brand p-2 w-2/5 rounded-md" >
                            <Preloader v-if="showPreloader"/>
                            <span v-else >Fund wallet</span>    
                        </button>
                    </form>
                </div>
                <div v-if="error.fundWallet" class="text-xs text-red-500 my-2">{{ error.fundWallet }}</div>
            </div>
        </div>

        <!-- Table -->
        <div class="mt-8 mb-14 bg-white p-6 pb-14 rounded-md shadow-md " >
            <div class="flex space-x-3">
                <div class="bg-gray-100 rounded text-sm shadow p-2">
                    <select @change="sortByDate" v-model="month" class="outline-none bg-transparent lg:w-32" >
                        <option value="">Sort by</option>
                        <option value="current">This Month</option>
                        <option value="2">Last 2 months</option>
                    </select>
                </div>
            </div>  
         <div class="mt-8 overflow-x-auto text-sm">
                <table class="lg:w-full w-150% table-fixed">
                    <thead>
                        <tr class="bg-gray-100">
                            <td v-for="row in rows" :key="row" class="p-2 font-semibold text-sm capitalize">{{ row }}</td>
                        </tr>
                    </thead>
                    <tbody v-if="walletHistory.length < 1" >
                        <tr class="border border-t-0" >
                            <td  class="text-sm p-3 text-center" :colspan="rows.length">{{ msg }}</td>  
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr v-for="col in walletHistory.slice(startPage,Endpage)" :key="col" class="border border-t-0" >
                            <td class="text-sm p-3">{{ datewithTime(col.transactionDate) }}</td> 
                            <td class="text-sm p-3">
                                <p class="capitalize">
                                    <span v-if=" col.transactionType == 'ORDER_PAYMENT'">
                                        Order Payment <br>
                                        {{ col.orderReference }}
                                    </span>
                                     <span v-else-if=" col.transactionType == 'REFUND'">
                                        Refund <br>
                                        {{ col.orderReference }}
                                    </span>
                                    <span v-else>
                                        {{ col.transactionType.replace("_"," ").toLowerCase() }}
                                    </span>
                                </p>
                            </td>  
                            <td class="text-sm p-3">
                               <p> {{ col.transactionReference }}</p>   
                            </td>   
                            <td class="text-sm p-3">
                                <span v-if="col.transactionDirection == 'CREDIT'">&#8358;{{ formatNumber(col.amount) }}</span> 
                                <span v-else class="text-red-500">-&#8358;{{ formatNumber(col.amount) }}</span> 
                            </td> 
                            <td class="text-sm p-3">&#8358;{{ (col.accountBalance) ? formatNumber(col.accountBalance) : "0.00" }}</td>   
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="lg:flex lg:justify-between bg-gray-100 h-12 px-4 py-4  items-center " >
                <div class="text-center text-sm"> {{ page + 1 }} of {{ columns.totalPages }} pages (Showing {{ getFirstItem }} - {{ getLastItem }} of {{ columns.length }} Entries)</div>
                <div class="flex lg:flex-grow justify-center  lg:justify-end" >
                    <div class="flex space-x-1">
                        <button @click="prev()" class="pagination-btn active text-sm">Prev</button>
                        <div  class="border border-gray-300  px-3 py-0.5" > {{ page + 1 }} </div>
                        <button @click="next()" class="pagination-btn active text-sm">Next</button>
                    </div>
                </div>
            </div>      
        </div>
    </Dashboard>  
</template>

<script>
const paystack = require("../../paystack/index.js")
import Preloader from "@/components/Preloader.vue";
import Dashboard from '@/components/customers/Dashboard.vue'
import moment from 'moment'
export default {
    name:"OrderHistory",
    components:{
        Dashboard,Preloader
    },
    data(){
        return{
            rows:['Date','Transaction Name','Reference number','Amount','Balance'],
            columns:{},
            showPreloader:false,
            error:{
                fundWallet:null,
                changePassword:""
            },
            success:{
                fundWallet:null,
                changePassword:""
            },
            tabs:{
                all:true,
                inprogress:false,
                delivered:false
            },
            oldpassword:"",
            password:"",
            cpassword:"",
            amount:"",
            startPage:0,
            Endpage:10,
            rowPerpage:10,
            numberofPages:0,
            paykey: process.env.VUE_APP_PAYSTACK_KEY,
            currentPage: 1,
            month:"",
            walletHistory:[],
            page:0,
            size:10,
            msg: 'Loading ...'
        }
    },
    computed: {
        editProfile(){
            return this.$store.getters.getUserProfile;
        },
        profile(){
            return this.$store.getters.getUserProfile;
        },
        getFirstItem(){
            return this.page * this.rowPerpage || 1
        },
        getLastItem(){
            return  this.page * this.rowPerpage + this.walletHistory.length
        },
    },
    methods:{
        fundWallet(){
            if(!this.amount){
                this.error.fundWallet = "Amount field is required"
            }else if(this.amount < 100){
                this.error.fundWallet= "Minimum deposit is ₦100"
            }else{
                this.error.fundWallet = ""
                let email = this.editProfile.email
                this.showPreloader = true;
                let ref = 'NW-' + this.reference();
                let paykey = this.paykey
                let amount = this.amount
                this.payWithPaystack(email,ref,paykey,amount)
            }
        },
        reference() {
            let text = "";
            let possible =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for (let i = 0; i < 8; i++)
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            return text;
        },
        payWithPaystack(email,ref,paykey,amount){
             let self = this
             let handler = PaystackPop.setup({
                key: paykey, 
                email:email,
                amount: amount * 100,
                ref: ref, 
                onClose: function(){
                },
                callback: function(response){
                    self.showPreloader = false;
                    self.editProfile.walletBalance = parseInt(self.editProfile.walletBalance) + parseInt(amount);
                    self.$store.dispatch('addUserProfile',self.editProfile);
                    self.amount = "";  
                    setTimeout(()=>{
                         self.getWalletHistory(); 
                    },1000);   
                }
            });
            handler.openIframe();
        },
        formatNumber(num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        },
        shortenTime(date){
            return moment(date).format("MMM Do, YYYY",' h:mm:ss a'); 
        },
        datewithTime(date){
            return moment(date).format('lll');   
        },
        async getWalletHistory(){
            let url = this.$store.state.base_url + `wallet/transactions`;
            try {
                let token = await this.$store.getters.getToken
                let res = await this.axios.get(url,{
                    headers:{
                        Authorization: 'Bearer ' + token  
                    },
                    params:{
                        page:this.page,
                        size:this.size
                    }
                });
                this.columns = res.data
                this.walletHistory = res.data.content.sort(function (a, b) {
                    let dateA = new Date(a.transactionDate)
                    let dateB = new Date(b.transactionDate)
                    return dateB - dateA 
                });
                this.msg = "No data found"
            } catch (error) {
                this.msg = "No data found"
                console.log(error);
            }
        },
        prev(){       
            if (this.page - 1 >= 0) {
                this.walletHistory = []
                this.page --
                this.getWalletHistory()          
            }
        },
        next(){
            if (this.page + 1 < this.columns.totalPages) {
                this.page ++
                this.walletHistory = []
                this.getWalletHistory()          
            }
        },
        sortByDate(){
            if(this.month == "current"){
                this.searchResult = this.columns.ConvertInvalidJsxElement.filter(col => col.adminResponse != null);
                this.reviews = this.searchResult 
            }else if(this.status == "published"){
                this.searchResult = this.columns.content.filter(col => col.isPublished == true);
                this.reviews = this.searchResult 
            }else if (!this.status) {
                this.reviews = this.columns.content
            }
        }
    },
    mounted(){
        this.getWalletHistory();

    }
}
</script>
