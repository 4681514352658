<template>
    <Dashboard>
        <h1 class="text-2xl font-semibold">Reviews</h1>
        <!-- Table -->
        <div class="mt-8 mb-16 bg-white px-6 pb-20 pt-6 lg:py-6 rounded-md shadow-md" >
            <div class="flex justify-between space-x-3">
                <div class="bg-gray-100 rounded text-sm shadow p-2">
                    <select @change="sortByStatus" v-model="status" class="outline-none mb-0 text-sm bg-transparent w-32" >
                        <option value="">Status</option>
                        <option value="replied">Replied</option>
                        <option value="published">published</option>
                    </select>
                </div>
                <div class="bg-gray-100 rounded text-sm shadow p-2">
                    <input type="text" v-model="search" @input="sortReviews" name="" id="" placeholder="Search ..." class="outline-none bg-transparent w-32 lg:w-48">
                </div>
            </div>
            <div class="mt-8 overflow-x-auto text-sm">
                <table class="lg:w-full w-150% table-fixed">
                    <thead>
                        <tr class="bg-gray-100">
                            <td v-for="row in rows" :key="row" :class="{'w-32 lg:w-auto' : row == 'Action'}" class="p-2 capitalize">{{ row }}</td>
                        </tr>
                    </thead>
                    <tbody v-if="!columns.length">
                        <tr class="border border-t-0" >
                            <td  class="text-sm p-3 text-center" :colspan="rows.length">{{ msg }}</td>  
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr v-for="col,index in reviews.slice(startPage,Endpage)" :key="col" class="border border-t-0" >
                            <td class="text-sm p-3">{{ index +1 }}</td> 
                            <td class="text-sm p-3">{{ col.orderReferenceNumber }}</td>  
                            <td class="text-sm p-3">{{ datewithTime(col.createdAt) }}</td> 
                            <td class="text-xs p-3">
                                <span v-if="col.adminResponse" class="bg-green-200 rounded-full text-green-600 inline px-2 py-1">
                                    Replied
                                </span>  
                                <span v-else class="bg-yellow-200 rounded-full text-yellow-600 inline px-2 py-1">
                                    Pending
                                </span>   
                            </td>   
                            <td class="text-sm p-3 w-44"><button @click="showReviewData(col.id)" class="bg-brand px-3 text-xs py-2 font-semibold rounded">View Review</button></td>   
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="lg:flex lg:justify-between bg-gray-100 h-12 px-4 pb-4 text-sm  items-center " >
                <div class="text-center p-4"> {{ currentPage }} of {{ numberofPages }} pages (Showing {{ getFirstItem }} - {{ getLastItem }} of {{ columns.length }} Entries)</div>
                <div class="flex lg:flex-grow justify-center  lg:justify-end" >
                    <div class="flex space-x-1">
                        <button @click="prev(currentPage)" class="pagination-btn active text-sm">Prev</button>
                        <div  class="border border-gray-300  px-3 py-0.5" > {{ currentPage }} </div>
                        <button @click="next(currentPage)" class="pagination-btn active text-sm">Next</button>
                    </div>
                </div>
            </div>
          
        </div>
    </Dashboard>  
    <Modal v-if="showReview" :show="true" width="w-768">
        <div class="grid grid-cols-2 pb-3 border-b">
            <h1 class="text-2xl font-semibold">Rate & Review</h1>
            <div class="flex justify-end">
                <button @click="toogleReview">
                    <svg class="h-8 w-8 text-black"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                    </svg>
                </button>
            </div>
        </div>
        <div class="flex space-x-2 my-4 lg:w-1/2">
            <div class="h-8 w-8 flex justify-center items-center bg-brand rounded-full">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
            </div>
            <div class="flex-grow">
                <p class="text-right text-xs mb-2 text-gray-400" >{{ datewithTime(singleReview.createdAt) }}</p>
                <div class="bg-brand bg-opacity-25 rounded-tl-none  rounded-xl  p-3">
                    <div class="flex space-x-2 items-center mb-2"> 
                        <star-rating :increment="0.5" :rating="singleReview.rating" inactive-color="#fff" border-color="#000" active-color="#DAA520" :border-width="2" :star-size="10" :read-only=true :show-rating=false />
                        <p class="text-sm">{{ singleReview.reviewTitle }}</p>
                    </div>
                    <p class="text-sm">{{ singleReview.reviewComment}}</p>
                </div>
            </div>  
        </div>
        <div  v-if="singleReview.adminResponse"  class="flex justify-end my-4">
            <div class="flex space-x-2 my-4 w-full lg:w-1/2">
                <div class="flex-grow">
                    <div class="flex justify-between">
                        <p class="text-right text-xs mb-2 text-gray-400" >{{ datewithTime(singleReview.createdAt) }}</p>
                        <p class="text-sm" >Noshtrybe</p>
                    </div>
                    <div class="bg-gray-100  rounded-tr-none  rounded-xl  p-3">
                        <p class="text-sm">{{ singleReview.adminResponse}}</p>
                    </div>
                </div>
                <div class="h-8 w-8 flex flex-shrink-0 justify-center items-center bg-gray-200 rounded-full">
                    <img src="@/assets/img/short.png" class="h-4 w-4" alt="">
                </div>
            </div>
        </div>
        <div class="mt-4" >
            <div class="flex justify-end text-sm" >
                <button @click="toogleReview" class="bg-gray-200 py-2 px-3 rounded text-black">
                    Close
                </button>
            </div>
        </div>
    </Modal>
</template>

<script>
import Preloader from "@/components/Preloader.vue";
import Dashboard from '@/components/customers/Dashboard.vue'
import Modal from '@/components/Modal.vue';
import StarRating from 'vue-star-rating';
import moment from 'moment'
export default {
    name:"OrderHistory",
    components:{
        Dashboard,Preloader,Modal,StarRating
    },
    data(){
        return{
            rows:['S/N','Order id','Review Date','Review Status','Action'],
            columns:[],
            showPreloader:false,
            showReview:false,
            singleReview:{},
            currentPage:1, 
            startPage : 0,
            Endpage:10,
            rowPerpage:10,
            numberofPages:0,
            msg:"Loading ...",
            search:"",
            status:"",
            reviews:[]
        }
    },
    computed:{
        getFirstItem(){
            return this.columns.findIndex((item)=> item.id == this.columns.slice(this.startPage, this.Endpage)[0].id ) + 1
        },
        getLastItem(){
            return this.columns.findIndex((item)=> item.id == this.columns.slice(this.startPage, this.Endpage)[this.columns.slice(this.startPage, this.Endpage).length - 1].id ) + 1
        }
    },
    methods:{
        toogleReview(){
            this.showReview = !this.showReview
        },
        formatNumber(num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        },
        shortenTime(date){
            return moment(date).format("MMM Do, YYYY",' h:mm:ss a'); 
        },
        datewithTime(date){
            return moment(date).format('lll');   
        },
        async getReviews(){
            let url = this.$store.state.base_url + `review/public/user`;
            try {
                let token = await this.$store.getters.getToken
                let res = await this.axios.get(url,{
                    headers:{
                        Authorization: 'Bearer ' + token  
                    }
                });
                this.columns = this.reviews = res.data.sort(function (a, b) {
                    let dateA = new Date(a.createdAt)
                    let dateB = new Date(b.createdAt)
                    return dateB - dateA 
                });
                this.numberofPages = Math.ceil(this.columns.length/this.rowPerpage);

                if (!this.columns.length) {
                    this.msg = "No data found";
                }
            } catch (error) {
                console.log(error);
                this.msg = "No data found";
            }
        },
        showReviewData(id){
            let review = this.columns.find((item) => item.id == id);
            this.singleReview = review;
            this.toogleReview();
        },
        sortReviews(){
            this.searchResult = this.columns.filter(col => col.orderReferenceNumber.toLowerCase().includes(this.search.toLowerCase()));
            this.reviews = this.searchResult
            if (this.searchResult.length  < 1 ) {
                this.reviews = this.columns
            }
        },
        sortByStatus(){
            if(this.status == "replied"){
                this.searchResult = this.columns.filter(col => col.adminResponse != null);
                this.reviews = this.searchResult 
            }else if(this.status == "published"){
                this.searchResult = this.columns.filter(col => col.isPublished == true);
                this.reviews = this.searchResult 
            }else if (!this.status) {
                this.reviews = this.columns
            }
        }
    },
    mounted(){
        this.getReviews();
    }
}
</script>
