<template>
  <div
    ref="sidebar"
    class="hidden w-64 flex-shrink-0 lg:flex flex-col transition duration-300 transform p-6 bg-white py-5"
  >
    <div class="p-3 flex items-center justify-between">
      <div>
        <img src="@/assets/svg/wallet-01.svg" class="h-8 w-8" alt="" />
      </div>
      <div class="bg-brand py-2 px-6 font-semibold rounded-md">
        &#8358;
        {{
          profile.walletBalance ? formatNumber(profile.walletBalance) : "0.00"
        }}
      </div>
    </div>
    <ul class="my-6 grid grid-cols-1 gap-3">
      <li class="">
        <router-link
          :to="{ name: 'OrderHistory' }"
          class="p-3 text-sm rounded-md flex space-x-3 font-semibold items-center flex-grow"
          :class="{ 'text-black bg-brand': this.$route.name == 'OrderHistory' }"
        >
          <div>
            <img
              src="@/assets/svg/order_history-01.svg"
              class="h-6 w-6"
              alt=""
            />
          </div>
          <p>Order History</p>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'Reviews' }"
          class="p-3 text-sm rounded-md flex space-x-3 font-semibold items-center flex-grow"
          :class="{ 'text-black bg-brand': this.$route.name == 'Reviews' }"
        >
          <div>
            <img src="@/assets/svg/review.svg" class="h-5 w-5" alt="" />
          </div>
          <p>Reviews</p>
        </router-link>
      </li>
      <li class="">
        <router-link
          :to="{ name: 'WalletHistory' }"
          class="p-3 text-sm rounded-md flex space-x-3 font-semibold items-center flex-grow"
          :class="{
            'text-black bg-brand': this.$route.name == 'WalletHistory',
          }"
        >
          <div>
            <img
              src="https://img.icons8.com/ios/50/000000/folder-invoices--v2.png"
              class="h-5 w-5"
            />
          </div>
          <p>Wallet</p>
        </router-link>
      </li>
      <li class="">
        <router-link
          :to="{ name: 'Referral' }"
          class="p-3 pl-2 text-sm rounded-md flex space-x-3 font-semibold items-center flex-grow"
          :class="{ 'text-black bg-brand': this.$route.name == 'Referral' }"
        >
          <div>
            <span class="h-5 w-5">
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.3927 8.14692L18.6457 6.62985C17.3871 5.53677 16.8937 4.94876 16.2121 5.15663C15.3622 5.41583 15.642 7.05133 15.642 7.60348C14.3206 7.60348 12.9468 7.50185 11.6443 7.7136C7.34453 8.41266 6 11.4718 6 14.7677C7.21697 14.0217 8.43274 13.1898 9.8954 12.8441C11.7212 12.4125 13.7603 12.6184 15.642 12.6184C15.642 13.1706 15.3622 14.8061 16.2121 15.0653C16.9844 15.3008 17.3871 14.6851 18.6457 13.5921L20.3927 12.075C21.4642 11.1445 22 10.6792 22 10.111C22 9.54274 21.4642 9.07747 20.3927 8.14692Z"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.5676 3.11523C6.70735 3.12217 4.68594 3.21675 3.39411 4.50596C2 5.89725 2 8.13648 2 12.6149C2 17.0934 2 19.3326 3.3941 20.7239C4.78821 22.1152 7.03198 22.1152 11.5195 22.1152C16.0071 22.1152 18.2509 22.1152 19.645 20.7239C20.6156 19.7552 20.9104 18.3755 21 16.1152"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <p>Referral</p>
        </router-link>
      </li>
      <li class="">
        <router-link
          :to="{ name: 'Settings' }"
          class="p-3 text-sm rounded-md flex space-x-3 font-semibold items-center flex-grow"
          :class="{ 'text-black bg-brand': this.$route.name == 'Settings' }"
        >
          <div>
            <img
              src="@/assets/svg/account_setting-01.svg"
              class="h-5 w-5"
              alt=""
            />
          </div>
          <p>Account Setting</p>
        </router-link>
      </li>
      <li class="">
        <button
          @click="logout"
          class="p-3 text-sm rounded-md flex space-x-3 font-semibold items-center cursor-pointer flex-grow"
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <p>Log out</p>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  props: ["profile"],
  methods: {
    logout() {
      this.$store.dispatch("removeUserToken");
      this.$store.dispatch("emptyCart");
      this.$store.dispatch("emptyResurantName");
      this.$store.dispatch("emptyTimebelt");
      this.$router.push({ name: "CustomerSignIn" });
    },
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
  },
};
</script>

<style>
</style>