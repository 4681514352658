<template>
    <div class="flex flex-col h-screen" >
        <div class="shadow-md">
            <Header/>
        </div>
        <div class="flex-1">
            <Auth width="md:w-2/5" background="bg-white" height="h-full">
                <div class="lg:mx-14 mx-3 md:mx-7 py-16 md:py-0 w-full">
                    <h1 class="font-semibold text-4xl" >Log in</h1>
                    <p class="mt-4">Welcome back ! Please login to your account</p>
                    <form @submit.prevent="LoginUser" class="mt-4">
                        <div v-if="error.empty" class="text-sm text-red-500 my-4" >{{ error.empty }}</div>
                        <div v-if="success" class="text-sm text-green-500 my-4" >{{ success }}</div>
                        <div>
                            <label for="email">Email Address</label>
                            <input type="text" v-model="email" class="input" placeholder="Email Address">
                            <span v-if="error.email" class="text-sm text-red-500 my-4">{{ error.email }}</span>
                        </div>
                        <div class="mt-2" >
                            <label for="password">Password</label>
                            <input type="password" v-model="password" class="input" placeholder="Password"  autocomplete="off" >
                        </div>
                        <div class="grid grid-cols-2 gap-2 mt-4" >
                        <div class="" >
                                <input type="checkbox" v-model="stayLoggedIn"> Remember me
                            </div>
                            <div class="text-right" >
                                <router-link :to="{ name: 'CustomerForgotPassword' }">Forgot password?</router-link>
                            </div>
                        </div>
                        <div class="mt-4" >
                            <button ref="loginBtn" class="bg-brand flex justify-center w-full p-3  font-semibold rounded">
                                <Preloader v-if="showPreloader"/>
                                <span v-else >Log in</span> 
                            </button>
                            <p class="flex-grow flex items-center justify-center md:justify-start mt-8" >Don't have an account? <router-link :to="{ name: 'CustomerSignUp' }" class="text-brand ml-1 font-semibold"> Sign up</router-link></p>
                        </div>
                    </form>        
                </div>
            </Auth>
        </div>
    </div>
</template>

<script>
import Auth from "@/components/admin/Auth.vue";
import Header from "@/components/Header.vue";  
import Preloader from "@/components/Preloader.vue";
export default { 
  name: 'CustomerLogin',
  components: {
      Auth,Preloader,Header
  },
  data(){
    return{
            email:"",
            password:"",
            success:null,
            stayLoggedIn:"",
            showPreloader:false,
            remember:false,
            error:{
                email:null,
                password:null,
                empty:null,
            }
        }
    },
    methods:{
        async LoginUser(){
            if (!this.email || !this.password ) {
                this.error.empty = "Email and  password is required"
            }else{
                let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                this.error.empty = null
                this.error.email = (!this.email.match(regexEmail)) ? "Invaild email entered" : null; 
                //Send credentials 
                if (!this.error.empty && !this.error.email) {
                    this.$refs.loginBtn.disabled = true;
                    this.showPreloader = true;
                    let data = {
                        email:this.email,
                        password:this.password,
                        authenticationDomain: "CUSTOMER_APP",
                        stayLoggedIn: this.stayLoggedIn
                    }
                    try {
                        let url = this.$store.state.base_url + 'login';
                        let login = await this.axios.post(url,data);
                        var CryptoJS = require("crypto-js");
                        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(login.data), process.env.VUE_APP_SECRET_PHRASE).toString();
                        let token = login.data.accessToken.token                       
                        url = this.$store.state.base_url + 'user-profile'; 
                        let profile = await this.axios.get(url,{
                            headers: {
                                Authorization: 'Bearer ' + token
                            }
                        })

                        this.$store.dispatch('addUserToken',ciphertext);
                        this.$store.dispatch('addUserProfile',profile.data )
                       
                        let cart = this.$store.getters.getCart
                        let dbcart = (this.$route.query.from == 'checkout' && (cart.cartItems.length || cart.containers.length) ) ? await this.global.getCart() : await this.global.unstructedCart();
                        if(cart.cartItems.length || dbcart.deliveryAddress  && this.$route.query.from != 'checkout'){
                            let address = (dbcart.deliveryAddress) ? dbcart.deliveryAddress : cart.cartItems[0].deliveryAddress;
                            let res = await this.axios.get('https://maps.googleapis.com/maps/api/geocode/json',{
                                params:{
                                    address: address,
                                    key: process.env.VUE_APP_GOOGLE_KEY
                                }
                            })
                            let geoData ={
                                address:address,
                                latitude: res.data.results[0].geometry.location.lat,
                                longitude:  res.data.results[0].geometry.location.lng
                            }
                            this.$store.dispatch('addDeliveryaddress',JSON.stringify(geoData));
                        }
                       
                        if(this.$route.query.from == 'checkout' && (cart.cartItems.length || cart.containers.length) ){
                            try{
                                await this.RedirectUser();
                            }catch(error){
                                console.log(error)   
                            }
                           
                        }else{
                            this.$store.dispatch('isUserLoggedin',true)
                            this.success = "Logging in user ...";
                            this.$router.push({ name: 'Home'})
                        }
                    } catch (err) {
                        console.log(err)
                        this.showPreloader = false;
                        this.$refs.loginBtn.disabled = false;
                        let message = err.response.data.message
                        let redirectError = "Please activate your account"
                        if(message == redirectError){
                            this.$router.push({ name: 'CustomerVerifyEmail', query: { email: this.email , from:"login"}})
                        }
                        this.error.empty = (message) ? message : null;
                    }
                }
            }
        },
        async RedirectUser(){
            //CREATE AN ORDER
            await this.global.saveCart();
            let res = await this.global.bookOrder()
            this.success = "Logging in user ...";
            this.$store.dispatch('isUserLoggedin',true)
            this.$store.dispatch('addOrder',JSON.stringify(res.data))
            this.$router.push({name: 'Checkout'})
        }
    }
}
</script>