<template>
  <Dashboard>
    <h1 class="lg:text-2xl text-xl my-6 lg:my-0 px-3 lg:px-0 font-semibold">
      Referral
    </h1>
    <div class="lg:flex lg:justify-between mb-0 mt-8 lg:space-x-4">
      <div
        class="flex p-6 space-x-4 my-4 items-center w-33 bg-white border border-gray-300 rounded-md"
      >
        <svg
          width="86"
          height="86"
          viewBox="0 0 86 86"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="42.6823" cy="43" r="42.6823" fill="#BCDB1B" />
          <path
            d="M40.7506 54.8515C40.573 54.8515 40.3921 54.8156 40.2197 54.7371C39.5761 54.4452 39.291 53.6855 39.5846 53.0419L43.8524 43.6528C44.1478 43.0092 44.9091 42.7275 45.5476 43.0177C46.1912 43.3096 46.4763 44.0693 46.1826 44.7129L41.9148 54.1021C41.7014 54.5732 41.2354 54.8515 40.7506 54.8515Z"
            fill="#507105"
          />
          <path
            d="M39.4701 46.3159C38.5277 46.3159 37.7629 45.5494 37.7629 44.6087C37.7629 43.6681 38.5277 42.9016 39.4701 42.9016C40.4124 42.9016 41.1772 43.6681 41.1772 44.6087C41.1772 45.5494 40.4124 46.3159 39.4701 46.3159Z"
            fill="#507105"
          />
          <path
            d="M46.2987 54.852C45.3563 54.852 44.5916 54.0855 44.5916 53.1449C44.5916 52.2042 45.3563 51.4377 46.2987 51.4377C47.241 51.4377 48.0058 52.2042 48.0058 53.1449C48.0058 54.0855 47.241 54.852 46.2987 54.852Z"
            fill="#507105"
          />
          <path
            d="M46.0768 33.5125C45.7012 33.5125 45.3291 33.3486 45.0764 33.0328C44.6343 32.4797 44.7248 31.6757 45.2762 31.2335L51.0787 26.5901C51.275 26.4331 51.2613 26.2453 51.217 26.1156C51.1726 25.9858 51.0684 25.8305 50.8226 25.8305H34.9464C34.7005 25.8305 34.5964 25.9841 34.552 26.1156C34.5076 26.247 34.494 26.4331 34.6886 26.5884L40.4928 31.2335C41.0442 31.6757 41.1347 32.4797 40.6925 33.0328C40.2504 33.5859 39.4463 33.6747 38.8915 33.2326L33.089 28.5892C32.0852 27.7885 31.7079 26.486 32.133 25.2705C32.5581 24.0551 33.6626 23.2698 34.9464 23.2698H50.8243C52.1081 23.2698 53.2109 24.0551 53.6359 25.2688C54.061 26.4826 53.6837 27.7868 52.6782 28.5892L46.8774 33.2308C46.6401 33.4203 46.3585 33.5125 46.0768 33.5125Z"
            fill="#507105"
          />
          <path
            d="M51.8467 33.5121H33.9219C33.2152 33.5121 32.6416 32.9385 32.6416 32.2318C32.6416 31.525 33.2152 30.9514 33.9219 30.9514H51.8467C52.5535 30.9514 53.1271 31.525 53.1271 32.2318C53.1271 32.9385 52.5535 33.5121 51.8467 33.5121Z"
            fill="#507105"
          />
          <path
            d="M48.8593 64.241H37.3362C30.5555 64.241 27.5203 58.4539 27.5203 52.7179C27.5203 44.7491 32.8021 34.66 38.8214 31.1279C39.4309 30.7694 40.2144 30.9743 40.5729 31.5855C40.9314 32.1949 40.7266 32.9802 40.1171 33.337C34.866 36.4183 30.0809 45.6556 30.0809 52.7179C30.0809 56.8526 31.981 61.6803 37.3362 61.6803H48.8593C55.022 61.6803 55.6878 55.4135 55.6878 52.7179C55.6878 45.6556 50.9027 36.4183 45.6499 33.337C45.0422 32.9802 44.8356 32.1949 45.1941 31.5855C45.5509 30.976 46.3294 30.7694 46.9456 31.1279C52.9667 34.66 58.2485 44.7491 58.2485 52.7179C58.2485 59.8264 54.6516 64.241 48.8593 64.241Z"
            fill="#507105"
          />
        </svg>
        <div class="flex items-center">
          <div>
            <p class="text-xs text-gray-500">Earned Bonus</p>
            <p class="font-semibold text-xl">
              &#8358;
              {{
                stats.totalIncentiveAmount
                  ? formatNumber(stats.totalIncentiveAmount)
                  : "0.00"
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="flex mt-6 my-3 items-end">
      <div class="w-40">
        <div class="text-sm text-gray-500">Referral Link</div>
        <div class="bg-white border-brand border p-2 rounded-md">
          <div class="flex justify-between">
            <input
              type="text"
              disabled
              class="bg-transparent outline-none w-3/5 px-2 text-sm"
              name=""
              id=""
              :placeholder="computedPlaceholder"
            />
            <button
              class="text-sm flex justify-center bg-brand p-2 w-1/4 rounded-md"
              @click="copyLink2"
            >
              <svg
                width="23"
                height="20"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.29907 13.8318C8.29907 11.2237 8.29907 9.91961 9.10932 9.10932C9.91961 8.29907 11.2237 8.29907 13.8318 8.29907H14.7539C17.3621 8.29907 18.6661 8.29907 19.4764 9.10932C20.2867 9.91961 20.2867 11.2237 20.2867 13.8318V14.7539C20.2867 17.3621 20.2867 18.6661 19.4764 19.4764C18.6661 20.2867 17.3621 20.2867 14.7539 20.2867H13.8318C11.2237 20.2867 9.91961 20.2867 9.10932 19.4764C8.29907 18.6661 8.29907 17.3621 8.29907 14.7539V13.8318Z"
                  stroke="black"
                  stroke-width="1.38318"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M15.676 8.29909C15.6738 5.57229 15.6325 4.15988 14.8388 3.19278C14.6855 3.00601 14.5143 2.83476 14.3276 2.68149C13.3073 1.84424 11.7916 1.84424 8.76015 1.84424C5.7287 1.84424 4.21297 1.84424 3.19278 2.68149C3.00601 2.83475 2.83476 3.00601 2.68149 3.19278C1.84424 4.21297 1.84424 5.7287 1.84424 8.76015C1.84424 11.7916 1.84424 13.3073 2.68149 14.3276C2.83475 14.5143 3.00601 14.6855 3.19278 14.8388C4.15988 15.6325 5.57229 15.6738 8.29909 15.676"
                  stroke="black"
                  stroke-width="1.38318"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <span>Copy</span>
            </button>
          </div>
        </div>
      </div>
      <div class="w-40 ml-4">
        <div class="text-sm text-gray-500">Referral Code</div>
        <div class="bg-white border-brand border p-2 rounded-md">
          <div class="flex justify-between">
            <input
              type="text"
              disabled
              class="bg-transparent outline-none w-3/5 px-2 text-sm"
              name=""
              id=""
              :placeholder="refCode"
            />
            <button
              class="text-sm flex justify-center bg-brand p-2 w-1/4 rounded-md"
              @click="copyLink"
            >
              <svg
                width="23"
                height="20"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.29907 13.8318C8.29907 11.2237 8.29907 9.91961 9.10932 9.10932C9.91961 8.29907 11.2237 8.29907 13.8318 8.29907H14.7539C17.3621 8.29907 18.6661 8.29907 19.4764 9.10932C20.2867 9.91961 20.2867 11.2237 20.2867 13.8318V14.7539C20.2867 17.3621 20.2867 18.6661 19.4764 19.4764C18.6661 20.2867 17.3621 20.2867 14.7539 20.2867H13.8318C11.2237 20.2867 9.91961 20.2867 9.10932 19.4764C8.29907 18.6661 8.29907 17.3621 8.29907 14.7539V13.8318Z"
                  stroke="black"
                  stroke-width="1.38318"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M15.676 8.29909C15.6738 5.57229 15.6325 4.15988 14.8388 3.19278C14.6855 3.00601 14.5143 2.83476 14.3276 2.68149C13.3073 1.84424 11.7916 1.84424 8.76015 1.84424C5.7287 1.84424 4.21297 1.84424 3.19278 2.68149C3.00601 2.83475 2.83476 3.00601 2.68149 3.19278C1.84424 4.21297 1.84424 5.7287 1.84424 8.76015C1.84424 11.7916 1.84424 13.3073 2.68149 14.3276C2.83475 14.5143 3.00601 14.6855 3.19278 14.8388C4.15988 15.6325 5.57229 15.6738 8.29909 15.676"
                  stroke="black"
                  stroke-width="1.38318"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <span>Copy</span>
            </button>
          </div>
        </div>
      </div>
      <div
        class="ml-2 text-xs p-2 px-4"
        style="
          background: transparent;
          color: black;
          height: 54px;
          border-radius: 8px;
          border: #c7dd04 1px solid;
        "
      >
        <div class="">Joined on</div>
        <div class="font-bold">
          {{ datewithTime(profile?.createdAt) }}
        </div>
      </div>
    </div>
    <!-- Table -->
    <div class="mt-8 mb-14 bg-white p-6 pb-14 rounded-md shadow-md">
      <div class="flex space-x-3"></div>
      <div class="mt-8 overflow-x-auto text-sm">
        <table class="lg:w-full w-150% table-fixed">
          <thead>
            <tr class="bg-gray-100">
              <td
                v-for="row in rows"
                :key="row"
                class="p-2 font-semibold text-sm capitalize"
              >
                {{ row }}
              </td>
            </tr>
          </thead>
          <tbody v-if="referralRecords.length < 1">
            <tr class="border border-t-0">
              <td class="text-sm p-3 text-center" :colspan="rows.length">
                {{ msg }}
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr
              v-for="col in referralRecords"
              :key="col"
              class="border border-t-0"
            >
              <td class="text-sm p-3">
                {{ datewithTime(col.dateInitiated) }}
              </td>
              <td class="text-sm p-3">
                <p>{{ col.refereeName }}</p>
              </td>
              <td class="text-sm p-3">
                <p>{{ col.refereePhoneNumber }}</p>
              </td>
              <td class="text-sm p-3">
                <p>
                  &#8358;{{
                    col.referrerReward && formatNumber(col.referrerReward)
                  }}
                </p>
              </td>
              <td class="text-sm p-3">
                <p>{{ col.refereeEmail }}</p>
              </td>

              <td class="text-sm p-3">
                <span>{{
                  col.referrerRewardRedeemedDate &&
                  datewithTime(col.referrerRewardRedeemedDate)
                }}</span>
              </td>
              <td class="text-sm p-3">
                <span
                  style="width: 90px; border-radius: 1rem"
                  v-if="col.status == 'PENDING'"
                  class="bg-yellow-400 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  PENDING
                </span>
                <span
                  style="width: 90px; border-radius: 1rem"
                  v-else
                  class="bg-blue-400 text-gray-100 text-xs font-semibold rounded-l-full px-3 py-1"
                >
                  {{ col.status }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="lg:flex lg:justify-between bg-gray-100 h-12 px-4 py-4 items-center"
      >
        <div class="text-center text-sm">
          {{ page + 1 }} of {{ columns.totalPages }} pages (Showing
          {{ getFirstItem }} - {{ getLastItem }} of
          {{ columns.length }} Entries)
        </div>
        <div class="flex lg:flex-grow justify-center lg:justify-end">
          <div class="flex space-x-1">
            <button @click="prev()" class="pagination-btn active text-sm">
              Prev
            </button>
            <div class="border border-gray-300 px-3 py-0.5">{{ page + 1 }}</div>
            <button @click="next()" class="pagination-btn active text-sm">
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  </Dashboard>
</template>

<script>
import Preloader from "@/components/Preloader.vue";
import Dashboard from "@/components/customers/Dashboard.vue";
import moment from "moment";
export default {
  name: "Referral",
  components: {
    Dashboard,
    Preloader,
  },
  data() {
    return {
      rows: [
        "Date",
        "Full Name",
        "Phone",
        "Reward",
        "Email",
        "Redeem Date",
        "Status",
      ],
      columns: {},
      stats: {},
      showPreloader: false,
      error: {
        changePassword: "",
      },
      success: {
        changePassword: "",
      },
      tabs: {
        all: true,
        inprogress: false,
        delivered: false,
      },
      oldpassword: "",
      password: "",
      cpassword: "",
      amount: "",
      startPage: 0,
      Endpage: 10,
      rowPerpage: 10,
      numberofPages: 0,
      currentPage: 1,
      month: "",
      walletHistory: [],
      referralRecords: [],
      refCode: "",
      page: 0,
      size: 10,
      msg: "Loading ...",
    };
  },
  computed: {
    profile() {
      return this.$store.getters.getUserProfile;
    },
    getFirstItem() {
      return this.page * this.rowPerpage || 1;
    },
    getLastItem() {
      return this.page * this.rowPerpage + this.walletHistory.length;
    },
    computedPlaceholder() {
      return `${window.location.origin}?referralCode=${this.refCode}`;
    },
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    shortenTime(date) {
      return moment(date).format("MMM Do, YYYY", " h:mm:ss a");
    },
    datewithTime(date) {
      return moment(date).format("lll");
    },
    async getReferralStats() {
      let url = this.$store.state.base_url + `user-registration-referrals`;
      try {
        let token = await this.$store.getters.getToken;
        let res = await this.axios.get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
          // params: {
          //   page: this.page,
          //   size: this.size,
          // },
        });
        this.refCode = res.data.code;
        this.stats = res.data.incentiveInfo;
      } catch (error) {
        this.msg = "Something went wrong";
      }
    },
    async getReferralRecords() {
      let url =
        this.$store.state.base_url + `user-registration-referrals/rewards`;
      try {
        let token = await this.$store.getters.getToken;
        let res = await this.axios.get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: {
            page: this.page,
            size: this.size,
          },
        });
        this.columns = res.data.content;
        this.referralRecords = res.data.content;
        this.msg = "No data found";
      } catch (error) {
        this.msg = "No data found";
        console.log(error);
      }
    },
    prev() {
      if (this.page - 1 >= 0) {
        this.referralRecords = [];
        this.page--;
        this.getReferralRecords();
      }
    },
    next() {
      if (this.page + 1 < this.columns.totalPages) {
        this.page++;
        this.referralRecords = [];
        this.getReferralRecords();
      }
    },
    sortByDate() {
      if (this.month == "current") {
        this.searchResult = this.columns.ConvertInvalidJsxElement.filter(
          (col) => col.adminResponse != null
        );
        this.reviews = this.searchResult;
      } else if (this.status == "published") {
        this.searchResult = this.columns.content.filter(
          (col) => col.isPublished == true
        );
        this.reviews = this.searchResult;
      } else if (!this.status) {
        this.reviews = this.columns.content;
      }
    },
    copyLink2() {
      const currentUrl = window.location.origin;
      const linkToCopy = `${currentUrl}/register?referralCode=${this.refCode}`;
      const inputElement = document.createElement("input");
      inputElement.value = linkToCopy;
      document.body.appendChild(inputElement);
      inputElement.select();
      inputElement.setSelectionRange(0, 99999); // For mobile devices
      document.execCommand("copy");
      // Remove the temporary input element
      document.body.removeChild(inputElement);
      // Optionally, you can provide feedback to the user using a notification library or other methods
      alert("Link copied to clipboard: " + linkToCopy);
    },
    copyLink() {
      const linkToCopy = `${this.refCode}`;
      const inputElement = document.createElement("input");
      inputElement.value = linkToCopy;
      document.body.appendChild(inputElement);
      inputElement.select();
      inputElement.setSelectionRange(0, 99999); // For mobile devices
      document.execCommand("copy");
      document.body.removeChild(inputElement);
      // Optionally, you can provide feedback to the user using a notification library or other methods
      alert("Link copied to clipboard: " + linkToCopy);
    },
  },
  mounted() {
    this.getReferralRecords();
    this.getReferralStats();
  },
};
</script>
<style>
@media (min-width: 768px) {
  .w-33 {
    width: 33%;
  }
  .w-40 {
    width: 40%;
  }
}
</style>