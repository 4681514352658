<template>
    <div class="flex flex-col h-screen" >
        <div class="shadow-md">
            <Header/>
        </div>
        <div class="flex-1">
            <Auth  background="bg-white" height="h-full">
                <div class="md:mx-14 mx-3">
                    <h1 class="font-semibold text-4xl" >Change password</h1>
                    <p class="mt-4"></p>
                    <form @submit.prevent="ChangePassword" class="mt-4">
                        <div v-if="error.empty" class="text-sm text-red-500 my-4" >{{ error.empty }}</div>
                        <div v-if="success" class="text-sm text-green-500 my-4" >{{ success }}</div>
                        <div class="mt-2" >
                            <label for="password">Password</label>
                            <input type="password" v-model="password" class="input" placeholder="Password"  autocomplete="off" >
                        </div>
                        <div class="mt-2" >
                            <label for="password">Confirm password</label>
                            <input type="password" v-model="cpassword" class="input" placeholder="Confirm password"  autocomplete="off" >
                        </div>
                        <div class="mt-4" >
                            <button :disabled="showPreloader" class="bg-brand px-3 py-2 font-semibold rounded">
                                <Preloader v-if="showPreloader"/>
                                <span v-else >Change password</span> 
                            </button>
                        </div>
                    </form>       
                </div>
            </Auth>
        </div>
    </div>
</template>

<script>
import Auth from "@/components/admin/Auth.vue";

import Header from "@/components/Header.vue";  
import Preloader from "@/components/Preloader.vue";
export default {
    name:['CustomerChangePassword'],
    components:{
        Auth,Preloader,Header
    },
    data(){
    return{
            password:"",
            cpassword:"",
            success:false,
            showPreloader:false,
            error:{
                email:null,
                empty:null,
            }
        }
    },
    methods: {
        ChangePassword(){
           if(this.password.length < 6 ){
               this.error.empty = "Password too short"
           }else if(this.password != this.cpassword){
               this.error.empty = "Password do not match"
           }else if(!this.$route.query.token){
               this.error.empty = "Token not found"
           }else{
               this.showPreloader = true
               let url = this.$store.state.base_url + 'reset-password';
               let data = {
                   token: this.$route.query.token,
                   confirmPassword: this.cpassword,
                   password:this.password,
               }
               this.axios.post(url,data)
                .then((res)=>{
                    this.success =  res.data.message;
                    this.showPreloader = false;
                    this.$router.push({ name: 'CustomerSignIn' })
                })
                .catch((err)=>{
                    this.showPreloader = false;
                    this.error.empty = err.response.data.message;
                })
           }
        }
    
    }
}
</script>

<style>
:root{
	--loader-width: 20px;
	--loader-height: 20px;
	--loader-color-primary: #000000;
	--loader-color-secondary: #eee;
	--line-width: 2px;
	--animation-duration: 2s;
	--loader-initial-scale: 0.1;
}
</style>